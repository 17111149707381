import { Header } from 'components/Modules/Header';
import { Layout } from 'components/common/Layout';
import { FC, useEffect } from 'react';
import { ReactComponent as HeaderIcon } from 'assets/Icons/esg-credibility-action-score.svg';
import { Box } from 'components/common/Box';
import { ESGCredibilityActionScoreTable } from 'components/Modules/Tables/ESGCredibilityActionScoreTable';

export const ESGCredibilityActionScore: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout headerVariant="secondary">
      <Box px={2.5} pt={2.3} pb={2.5}>
        <Header
          title="ESG Credibility & Action Score"
          descriptions={[
            'Zur Bewertung der Nachhaltigkeitsbemühungen eines einzelnen Fonds bzw. einer ganzen Fondsgesellschaft hat FondsConsult zwei proprietäre Scores entwickelt, welche Ihnen durch die gezielte Kombination von Top-down- und Bottom-up-Ansätzen ein umfangreiches Gesamtbild liefern. Mit unseren Scores erhalten Sie eine fundierte Grundlage für Ihre Anlageentscheidungen und können das Fondsuniversum gezielt nach Ihren gewünschten Nachhaltigkeitsstandards filtern.',
          ]}
          showMoreButtonText="Mehr zur Methodik anzeigen"
          showMoreTexts={[
            'Der ESG Credibility Score (Top-down) bewertet die Glaubwürdigkeit der Nachhaltigkeitsbemühungen einer Fondsgesellschaft. Datengrundlage dafür liefern die UN PRI Transparency Reports. Anhand rund 100 ausgewählter Fragen bewerten wir über Kriterien, wie Engagement, Abdeckung, Integrität, Monitoring, Verantwortung und Transparenz den Nachhaltigkeitsansatz eines Fondsanbieters. Somit werden neben dem Produktangebot auch interne Prozesse und Vorgehensweisen bewertet. Der ESG Credibility Score bewegt sich auf einer Skala von 0 bis 100, wobei Werte über 60 eine hohe Glaubwürdigkeit zeigen. Ein Score kleiner 40 spricht für eine niedrige Glaubwürdigkeit bzw. keinen (firmenweit gelebten) Nachhaltigkeitsansatz.',
            'Der ESG Action Score (Bottom-up) misst dagegen auf Fondsebene die tatsächlich umgesetzten Nachhaltigkeitskriterien. Bei der Berechnung dieses Scores bedienen wir uns ausgewählter Datenpunkte aus den sogenannten European ESG Templates (kurz EET). Anhand von Offenlegungskriterien (bspw. SFDR, MiFID) sowie berücksichtigter Nachhaltigkeitskriterien (PAIs) erhält jeder Fonds einen Score von 0 bis 100. Der ESG Action Score einer Fondsgesellschaft ergibt sich aus den volumengewichteten Einzelscores. Ein Gesamtscore größer 60 repräsentiert ein Produktangebot mit überdurchschnittlichen Nachhaltigkeitsmerkmalen, während ein Score kleiner 40 für eine unterdurchschnittliche Berücksichtigung von ESG Kriterien spricht.',
            'Weitere Informationen zur Methodik und den Ergebnissen unserer jüngsten Erhebung erhalten sie im Bereich „FondsConsult Studien“.',
          ]}
          icon={<HeaderIcon />}
        />
      </Box>
      <ESGCredibilityActionScoreTable variant="standart" />
    </Layout>
  );
};
