import { Header } from 'components/Modules/Header';
import { Layout } from 'components/common/Layout';
import { FC, useEffect } from 'react';
import { ReactComponent as HeaderIcon } from 'assets/Icons/arrange-square.svg';
import { Box } from 'components/common/Box';
import { ComparisonListContent } from 'components/Modules/ComparisonListContent';

export const ComparisonList: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout headerVariant="secondary">
      <Box px={2.5} pt={2.3} pb={2.5}>
        <Header
          title="Mein Fondsvergleich"
          descriptions={[
            'Nutzen Sie unsere Fondsvergleichsfunktion, um mehrere Produkte direkt miteinander vergleichen zu können. So sehen Sie auf einen Blick, über welche Eigenschaften und Charakteristika sich ein Fonds auszeichnet bzw. wo sich verschiedene Produkte (derselben Vergleichsgruppe) voneinander unterscheiden. Neben den qualitativen Besonderheiten können Sie auch das quantitative Abschneiden sowohl relativ (Tabelle) als auch absolut (Graphik) einsehen.',
          ]}
          icon={<HeaderIcon />}
        />
      </Box>
      <ComparisonListContent />
    </Layout>
  );
};
