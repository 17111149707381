import { StartView } from 'components/User/StartView';
import { FC } from 'react';
import { ReactComponent as ConfirmIcon } from 'assets/Icons/done.svg';
import { Typography } from 'components/common/Typography';
import { Button } from 'components/common/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { AppPaths } from 'urls/frontend';
import { Box } from 'components/common/Box';
import { COLORS } from 'theme/colors';

export const ResetPasswordConfirmed: FC = () => {
  const navigate = useNavigate();

  return (
    <StartView headline="Ihr Passwort wurde geändert" icon={<ConfirmIcon />}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={2}
        style={{ width: '33%', margin: '25px auto' }}
      >
        <Typography variant="body" color={COLORS.accent.secondary}>
          Sie können sich jetzt mit Ihrem neuen Passwort anmelden
        </Typography>
        <Button onClick={() => navigate(AppPaths.login)}>
          Zurück zum Login
        </Button>
      </Box>
    </StartView>
  );
};
