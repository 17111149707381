import fetchAPI from './fetchApi';
import {
  CHANGE_COMPARISON_LIST_STATUS_SMART_BENCHMARKING,
  COMPARISON_LIST,
  COMPARISON_LIST_SEARCH,
} from 'urls/api';
import { ComparisonListItemProps } from 'types/ComparisonList.d';

export const fetchSmartBenchmarkingComparisonList = () =>
  fetchAPI<ComparisonListItemProps[]>(`${COMPARISON_LIST}`);

export const fetchUpdateComparisonListStatusSmartBenchmarking = (id: string) =>
  fetchAPI(CHANGE_COMPARISON_LIST_STATUS_SMART_BENCHMARKING(id), {
    method: 'PATCH',
  });

export const fetchComparisonListSearch = (query: string) =>
  fetchAPI<ComparisonListItemProps[]>(
    `${COMPARISON_LIST_SEARCH}?search=${query}`,
  );
