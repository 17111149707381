import { COLORS } from 'theme/colors';

export const backgroundColors = [
  COLORS.info.selectedBorder,
  COLORS.warning.primary,
  COLORS.error.background,
  COLORS.typography.placeholder,
  COLORS.success.bulletPoint,
];

export const chartLabels = ['1 year', '3 years', '5 years'];

export const chartLabelsNew = [
  { value: 'short_term', name: '1 year' },
  { value: 'medium_term', name: '3 years' },
  { value: 'long_term', name: '5 years' },
];
