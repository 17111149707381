import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { ComparisonListSearchProps } from './ComparisonListSearch.d';
import {
  StyledInput,
  StyledDropdown,
  StyledContainer,
  StyledInputIcon,
  StyledSubmitButtonContainer,
  StyledPlusIconContainer,
  StyledResultItem,
} from './ComparisonListSearch.styled';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { Box } from 'components/common/Box';
import { ComparisonListSearchResultProps } from 'types/ComparisonList';
import { ReactComponent as SearchIcon } from 'assets/Icons/search.svg';
import { ReactComponent as PlusIcon } from 'assets/Icons/plus.svg';
import { HighlightedText } from '../HighlightedText';
import { fetchComparisonListSearch } from 'services/ComparisonList';
import { debounce } from 'utils/common';

export const ComparisonListSearch: FC<ComparisonListSearchProps> = ({
  menuOpen,
  setMenuOpen,
  addToComparison,
}) => {
  const popperRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const toggleMenuOpen = () => {
    setMenuOpen(true);
  };

  const [searchResults, setSearchResults] = useState<
    ComparisonListSearchResultProps[]
  >([]);

  const getResults = async (query: string) => {
    if (query.length > 0) {
      const response = await fetchComparisonListSearch(query);
      if (response.ok) {
        setSearchResults(response.parsedBody);
      }
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMenuOpen(true);
    const inputValue = event.target.value;
    setSearchQuery(inputValue);
    setAnchorEl(event.currentTarget);
  };

  const onFocus = () => {
    searchQuery.length > 0 && toggleMenuOpen();
    setIsFocused(true);
  };

  const clearSearch = () => {
    setMenuOpen(false);
    setIsFocused(false);
  };

  const handleSearch = (value: string) => {
    if (value.length === 0) clearSearch();
    getResults(value);
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

  useEffect(() => {
    debouncedHandleSearch(searchQuery);
  }, [searchQuery, debouncedHandleSearch]);

  const onAddToComparisonClick = (id: string, name: string) => {
    addToComparison(id, name);
    getResults(searchQuery);
    setMenuOpen(false);
    setSearchQuery('');
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popperRef.current &&
      !popperRef.current.contains(event.target as Node)
    ) {
      setMenuOpen(false);
      setIsFocused(false);
    }
  };

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <Box>
      <StyledContainer>
        <StyledInputIcon isFocused={isFocused}>
          <SearchIcon stroke={COLORS.typography.placeholder} />
        </StyledInputIcon>
        <StyledInput
          value={searchQuery}
          onChange={onChange}
          onFocus={onFocus}
          placeholder="Fondssuche über ISIN oder Name..."
        />
        <StyledSubmitButtonContainer>
          <span>Hinzufügen</span>
          <StyledPlusIconContainer>
            <PlusIcon strokeWidth={1.7} />
          </StyledPlusIconContainer>
        </StyledSubmitButtonContainer>
      </StyledContainer>
      <StyledDropdown
        id="search-dropdown"
        open={menuOpen}
        placement="bottom-start"
        anchorEl={anchorEl}
        ref={popperRef}
        disablePortal
      >
        {searchResults.length === 0 && searchQuery.length > 0 ? (
          <Typography variant="body" color={COLORS.typography.body}>
            No results
          </Typography>
        ) : (
          searchResults?.map((result) => (
            <StyledResultItem
              key={result.id}
              onClick={() => onAddToComparisonClick(result.id, result.name)}
            >
              <HighlightedText text={result.name} searchQuery={searchQuery} />
            </StyledResultItem>
          ))
        )}
      </StyledDropdown>
    </Box>
  );
};
