import { ToggleButton, ToggleButtonGroup, styled } from '@mui/material';
import { COLORS } from 'theme/colors';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  '& .Mui-selected': {
    backgroundColor: `${COLORS.info.selectedBorder} !important`,
    color: `${COLORS.background.secondary} !important`,
    borderColor: COLORS.info.selectedBorder,
    '&:hover': {
      borderColor: COLORS.info.selectedBorder,
    },
  },
  '& .MuiToggleButtonGroup-lastButton': {
    borderLeftColor: `${COLORS.stroke.primary} !important`,
  },
});

export const StyledToggleButton = styled(ToggleButton)({
  borderRadius: '32px',
  textTransform: 'none',
  fontFamily: '"Inter", sans-serif',
  fontSize: '14px',
  fontWeight: 400,
  color: COLORS.typography.chip,
  padding: '5px 11px',
  width: '100px',
  borderColor: COLORS.stroke.primary,
  backgroundColor: COLORS.accent.disabled,
  '&:hover': {
    borderColor: COLORS.background.neutral,
    backgroundColor: COLORS.background.neutral,
    color: COLORS.info.selectedBorder,
  },
});
