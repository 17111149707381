import { forwardRef } from 'react';
import { InviteUserModalProps, IviteUserFormProps } from './InviteUserModal.d';
import {
  StyledContainer,
  StyledDescription,
  StyledErrorText,
  StyledForm,
  StyledTitle,
} from './InviteUserModal.styled';
import { Modal } from 'components/common/Modal/Modal';
import { Box } from 'components/common/Box';
import { ConsultantItem } from 'components/Overview/AnalystServiceDeskSection/ConsultantItem';
import { network, salutationOptions } from './config';
import { Button } from 'components/common/Buttons/Button';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { sendInvitation } from 'services/Invitation';
import { Input } from 'components/common/Input';
import { useForm } from 'react-hook-form';
import { inviteUserSchema } from './validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { isValidForm } from 'utils/isValidForm';
import { Typography } from 'components/common/Typography';
import { Radio } from 'components/common/Radio';

export const InviteUserModal = forwardRef<HTMLDivElement, InviteUserModalProps>(
  ({ isShown, toggle }, ref) => {
    const {
      register,
      handleSubmit,
      reset,
      watch,
      formState: { errors },
    } = useForm<IviteUserFormProps>({
      resolver: yupResolver(inviteUserSchema),
      mode: 'onBlur',
    });

    const salutation = watch('salutation');

    const onSubmit = async (formData: IviteUserFormProps) => {
      const response = await sendInvitation([
        {
          name: formData.first_name,
          last_name: formData.second_name,
          gender: formData.salutation,
          email: formData.email,
          as_admin: false,
        },
      ]);
      if (response.ok) {
        raiseToast.send(
          'Einladungslink wurde an den ausgewählten Benutzer gesendet.',
        );
        reset();
        toggle();
      }
    };

    return (
      <Modal
        modalRef={ref}
        isShown={isShown}
        onClose={toggle}
        withCloseButton
        withHelpBox
        helpBoxText="Bitte kontaktieren Sie uns, falls Sie mehr Informationen benötigen."
        header={
          <Box display="inline-flex" pr={0.5} pl={1.5} alignItems="center">
            {network.map((consultant) => (
              <ConsultantItem
                key={consultant.fullName}
                fullName={consultant.fullName}
                imageLink={consultant.imageLink}
                position={consultant.position}
                variant="network"
              />
            ))}
          </Box>
        }
        p={4}
      >
        <StyledContainer>
          <StyledTitle variant="h2">
            Benutzer zu Ihrem Arbeitsbereich hinzufügen
          </StyledTitle>
          <StyledDescription variant="body">
            Senden Sie Ihren Kollegen einen Einladungslink.
          </StyledDescription>
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="h6">Anrede</Typography>
              <Box display="flex" gap={1}>
                {salutationOptions.map((option) => (
                  <Radio
                    key={option.label}
                    {...register('salutation')}
                    value={option.value}
                    label={option.label}
                    selectedValue={salutation || ''}
                  />
                ))}
              </Box>
              {!isValidForm(errors) && !!errors.salutation?.message && (
                <StyledErrorText>{errors.salutation?.message}</StyledErrorText>
              )}
            </Box>
            <Input
              placeholder="Vorname"
              {...register('first_name', {
                required: true,
              })}
              error={!isValidForm(errors) && errors.first_name?.message}
            />
            <Input
              placeholder="Nachname"
              {...register('second_name', {
                required: true,
              })}
              error={!isValidForm(errors) && errors.second_name?.message}
            />
            <Input
              placeholder="E-Mail-Adresse"
              {...register('email', {
                required: true,
              })}
              error={!isValidForm(errors) && errors.email?.message}
            />
            <Box mt={2}>
              <Button type="submit">Senden</Button>
            </Box>
          </StyledForm>
        </StyledContainer>
      </Modal>
    );
  },
);
