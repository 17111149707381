import { FC } from 'react';
import { SelectableFilterChipProps } from './SelectableFilterChip.d';
import { StyledArticleIcon, StyledChip } from './SelectableFilterChip.styled';
import { ReactComponent as TrendUpIcon } from 'assets/Icons/trend-up.svg';
import { ReactComponent as TrendDownIcon } from 'assets/Icons/trend-down.svg';

export const SelectableFilterChip: FC<SelectableFilterChipProps> = ({
  label,
  selected,
  trend,
  article,
  disabled,
  onClick,
  ...props
}) => {
  const trendIcon = trend === 'up' ? <TrendUpIcon /> : <TrendDownIcon />;

  return (
    <StyledChip
      selected={selected}
      withArticle={!!article}
      icon={
        trend ? (
          trendIcon
        ) : article ? (
          <StyledArticleIcon selected={selected}>{article}</StyledArticleIcon>
        ) : undefined
      }
      label={label}
      disabled={disabled}
      onClick={onClick}
      {...props}
    />
  );
};
