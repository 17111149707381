import {
  RECOMMENDATIONS_FOR_ACTION,
  SMART_BENCHMARKING_FUND_DETAILS,
  SMART_BENCHMARKING_PEER_GROUP,
  SMART_BENCHMARKING_PEER_GROUPS,
} from 'urls/api';
import fetchAPI from './fetchApi';
import {
  SmartBenchmarkingFund,
  SmartBenchmarkingPeerGroup,
} from 'types/SmartBenchmarking.d';
import { SmartFundBenchmarkingItemProps } from 'types/Modules.d';
import { capitalizeWords } from 'utils/common';

export const fetchSmartBenchmarkingFund = (id: string) => {
  const fetchFundDetails = fetchAPI<SmartBenchmarkingFund>(
    SMART_BENCHMARKING_FUND_DETAILS(id),
  );
  return fetchFundDetails;
};

export interface PeerGroupsRequest {
  search?: string;
  asset_class?: string;
}

export const fetchSmartBenchmarkingPeerGroups = ({
  search,
  asset_class,
}: PeerGroupsRequest) => {
  const fetchPeerGroups = fetchAPI<SmartBenchmarkingPeerGroup[]>(
    `${SMART_BENCHMARKING_PEER_GROUPS}/${search ? `?search=${search}` : ''}${
      asset_class ? `${search ? '&' : '?'}asset_class=${asset_class}` : ''
    }`,
  );
  return fetchPeerGroups;
};

export interface SmartBenchmarkingRequest {
  [key: string]: string | number | string[];
}

type SmartBenchmarkingFunds = {
  funds: SmartFundBenchmarkingItemProps[];
};

interface SmartBenchmarkingResponse extends Response {
  results: SmartBenchmarkingFunds;
  count: number;
}

export const fetchSmartBenchmarkingPeerGroup = ({
  page,
  page_size,
  sort_by,
  sort_order,
  id,
}: SmartBenchmarkingRequest) => {
  const fetchSFB =
    id &&
    fetchAPI<SmartBenchmarkingResponse>(
      `${SMART_BENCHMARKING_PEER_GROUP(id.toString())}/${
        page ? `?page=${page}` : ''
      }${page_size ? `&page_size=${page_size}` : ''}${
        sort_by && sort_order
          ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
          : ''
      }`,
    );
  return fetchSFB;
};

export const fetchRecommendationsForAction = ({
  page,
  page_size,
  sort_by,
  sort_order,
  search,
  ...restValues
}: SmartBenchmarkingRequest) => {
  const fetchSFB = fetchAPI<SmartBenchmarkingResponse>(
    `${RECOMMENDATIONS_FOR_ACTION}/${page ? `?page=${page}` : ''}${
      page_size ? `&page_size=${page_size}` : ''
    }${
      sort_by && sort_order
        ? `&sort_by=${sort_order === 'asc' ? '-' : ''}${sort_by}`
        : ''
    }${search ? `&search=${search}` : ''}${
      Object.keys(restValues).length
        ? `&${Object.entries(restValues)
            .map(([key, value]) =>
              Array.isArray(value)
                ? value
                    .map(
                      (v) =>
                        `${encodeURIComponent(key)}=${encodeURIComponent(
                          capitalizeWords(String(v)),
                        )}`,
                    )
                    .join('&')
                : `${encodeURIComponent(key)}=${encodeURIComponent(
                    capitalizeWords(String(value)),
                  )}`,
            )
            .join('&')}`
        : ''
    }`,
  );
  return fetchSFB;
};
