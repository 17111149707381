import { forwardRef } from 'react';
import { TableChipProps } from './TableChip.d';
import { StyledChip } from './TableChip.styled';
import { TableChipVariant } from './TableChip.enums';

export const TableChip = forwardRef<HTMLDivElement, TableChipProps>(
  ({ label, size = 'medium', tableChipVaraint }, ref) => {
    return label === '-' || !label ? (
      <>-</>
    ) : (
      <StyledChip
        size={size}
        ref={ref}
        label={label}
        tableChipVaraint={tableChipVaraint || TableChipVariant.GREY}
      />
    );
  },
);
