export const regions = {
  region1: {
    label: 'Afrika/Mittlerer Osten',
    countries: ['Israel', 'Südafrika', 'Pakistan'],
  },
  region2: {
    label: 'Asien (Industrieländer)',
    countries: ['Hongkong', 'Singapur', 'Südkorea', 'Taiwan'],
  },
  region3: {
    label: 'Asien (Schwellenländer)',
    countries: [
      'China',
      'Indien',
      'Indonesien',
      'Malaysia',
      'Philippinen',
      'Thailand',
      'Vietnam',
    ],
  },
  region4: {
    label: 'Australasien',
    countries: ['Australien', 'Neuseeland'],
  },
  region5: {
    label: 'Europa (Industrieländer)',
    countries: [
      'Belgien',
      'Dänemark',
      'Deutschland',
      'Finnland',
      'Frankreich',
      'Griechenland',
      'Irland',
      'Italien',
      'Niederlande',
      'Norwegen',
      'Österreich',
      'Portugal',
      'Schweden',
      'Schweiz',
      'Spanien',
    ],
  },
  region6: {
    label: 'Europa (Schwellenländer)',
    countries: [
      'Estland',
      'Lettland',
      'Litauen',
      'Polen',
      'Russland',
      'Slowakei',
      'Tschechische Republik',
      'Ungarn',
    ],
  },
  region7: {
    label: 'Japan',
    countries: ['Japan'],
  },
  region8: {
    label: 'Lateinamerika',
    countries: [
      'Argentinien',
      'Brasilien',
      'Chile',
      'Kolumbien',
      'Mexiko',
      'Peru',
      'Venezuela',
    ],
  },
  region9: {
    label: 'Nordamerika',
    countries: ['Kanada', 'Vereinigte Staaten'],
  },
  region10: {
    label: 'Vereinigtes Königreich',
    countries: ['Vereinigtes Königreich'],
  },
};
