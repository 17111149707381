import { FC } from 'react';
import { HighlightedTextProps } from './HighlightedText.d';
import { StyledBoldText } from './HighlightedText.styled';

export const HighlightedText: FC<HighlightedTextProps> = ({
  text,
  searchQuery,
}) => {
  if (!searchQuery) return <span>{text}</span>;

  const parts: string[] = text.split(new RegExp(`(${searchQuery})`, 'gi'));

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === searchQuery.toLowerCase() ? (
          <StyledBoldText key={index}>{part}</StyledBoldText>
        ) : (
          <span key={index}>{part}</span>
        ),
      )}
    </span>
  );
};
