import { Button } from 'components/common/Buttons/Button';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledButton = styled(Button)`
  width: 220px;
  &:enabled {
    & svg {
      stroke: ${COLORS.accent.primary};
    }
    &:hover {
      opacity: 0.85;
      & svg {
        stroke: ${COLORS.accent.primary};
      }
    }
  }
`;
