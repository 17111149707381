import { FC, useRef } from 'react';
import { SlideOutFilterModalProps } from './SlideOutFilterModal.d';
import {
  StyledCloseButton,
  StyledFooter,
  StyledModalContent,
  StyledModalHeader,
  StyledSlideOutModal,
} from './SlideOutFilterModal.styled';
import { useClickOutside } from 'hooks/useClickOutside';
import { ReactComponent as CloseIcon } from 'assets/Icons/close.svg';
import { COLORS } from 'theme/colors';
import { Typography } from 'components/common/Typography';
import { Button } from 'components/common/Buttons/Button';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';

export const SlideOutFilterModal: FC<SlideOutFilterModalProps> = ({
  isShown,
  closeModal,
  clearFilters,
  applyFilters,
  variant = 'medium',
  children,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useClickOutside(wrapperRef, () => {
    closeModal();
  });

  return (
    <StyledSlideOutModal isShown={isShown} ref={wrapperRef} variant={variant}>
      <StyledModalHeader>
        <Typography variant="h4">Filter</Typography>
        <StyledCloseButton onClick={closeModal}>
          <CloseIcon stroke={COLORS.typography.placeholder} />
        </StyledCloseButton>
      </StyledModalHeader>
      <StyledModalContent>{children}</StyledModalContent>
      <StyledFooter>
        <Button variant={ButtonVariant.OUTLINED} onClick={clearFilters}>
          Alle Filter löschen
        </Button>
        <Button onClick={applyFilters}>Fonds anzeigen</Button>
      </StyledFooter>
    </StyledSlideOutModal>
  );
};
