import { FC } from 'react';
import { CrashDrawdownProps } from './CrashDrawdown.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { BoxWithBookmark } from '../BoxWithBookmark';
import {
  StyledTabContentContainer,
  StyledTabsContainer,
  StyledTypography,
} from './CrashDrawdown.styled';
import { TableTabs } from 'components/common/TableTabs';
import { TableChip } from 'components/common/TableChip';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { crashesInfo } from './config';
import { COLORS } from 'theme/colors';
import { BasicTable } from '../BasicTable';
import { getFilterByValue } from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/utils';
import { crashDrawdownsOptions } from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/config';

export const CrashDrawdown: FC<CrashDrawdownProps> = ({
  description,
  crash_2007_2009,
  crash_2011,
  crash_2015_2016,
  crash_2018,
  crash_2020,
  crash_2022,
}) => {
  const data = [
    {
      label: crashesInfo.crash_2007_2009.label,
      value: crash_2007_2009,
      periods: crashesInfo.crash_2007_2009.period,
      events: crashesInfo.crash_2007_2009.events,
      consequences: crashesInfo.crash_2007_2009.consequences,
    },
    {
      label: crashesInfo.crash_2011.label,
      value: crash_2011,
      periods: crashesInfo.crash_2011.period,
      events: crashesInfo.crash_2011.events,
      consequences: crashesInfo.crash_2011.consequences,
    },
    {
      label: crashesInfo.crash_2015_2016.label,
      value: crash_2015_2016,
      periods: crashesInfo.crash_2015_2016.period,
      events: crashesInfo.crash_2015_2016.events,
      consequences: crashesInfo.crash_2015_2016.consequences,
    },
    {
      label: crashesInfo.crash_2018.label,
      value: crash_2018,
      periods: crashesInfo.crash_2018.period,
      events: crashesInfo.crash_2018.events,
      consequences: crashesInfo.crash_2018.consequences,
    },
    {
      label: crashesInfo.crash_2020.label,
      value: crash_2020,
      periods: crashesInfo.crash_2020.period,
      events: crashesInfo.crash_2020.events,
      consequences: crashesInfo.crash_2020.consequences,
    },
    {
      label: 'Beginn Russland-Ukraine Krieg (2022)',
      value: crash_2022,
      periods: crashesInfo.crash_2022.period,
      events: crashesInfo.crash_2022.events,
      consequences: crashesInfo.crash_2022.consequences,
    },
  ];
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      {data.length !== 0 && (
        <BoxWithBookmark>
          <BasicTable
            px={2}
            pb={3}
            pt={1.5}
            columnNumber={3}
            rowNumber={2}
            data={data.map((item) => ({
              label: (
                <StyledTypography variant="body" weight="semibold">
                  {item.label}
                </StyledTypography>
              ),
              value: (
                <TableChip
                  label={item.value.toLowerCase() as ChipVariant}
                  size="small"
                  tableChipVaraint={
                    getFilterByValue(item.value, crashDrawdownsOptions)
                      ?.chipVariant
                  }
                />
              ),
            }))}
          />
        </BoxWithBookmark>
      )}
      <StyledTabsContainer>
        <TableTabs
          variant="scrollable"
          scrollButtons
          tabs={data.map((item) => ({
            label: item.label,
            content: (
              <StyledTabContentContainer display="grid" gridAutoFlow="column">
                {item.periods && (
                  <Box display="flex" flexDirection="column" gap={2}>
                    <Typography variant="body" weight="semibold">
                      Betrachteter Zeitraum
                    </Typography>
                    <StyledTypography variant="body">
                      {item.periods}
                    </StyledTypography>
                  </Box>
                )}
                {item.events && (
                  <Box display="flex" flexDirection="column" gap={2}>
                    <Typography variant="body" weight="semibold">
                      Ereignisse
                    </Typography>
                    <StyledTypography variant="body">
                      {item.events}
                    </StyledTypography>
                  </Box>
                )}
                {item.consequences && (
                  <Box display="flex" flexDirection="column" gap={2}>
                    <Typography variant="body" weight="semibold">
                      Folgen
                    </Typography>
                    <StyledTypography variant="body">
                      {item.consequences}
                    </StyledTypography>
                  </Box>
                )}
              </StyledTabContentContainer>
            ),
            variant: 'warning',
          }))}
        />
      </StyledTabsContainer>
    </Box>
  );
};
