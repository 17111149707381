import { Option } from 'components/common/Select/Select.d';

export const defaultOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch' },
  { value: 'Mittel', name: 'Mittel' },
  { value: 'Niedrig', name: 'Niedrig' },
];

export const valueToGrowthAbsOptions: Option[] = [
  { value: 'Deep Value', name: 'Deep Value' },
  { value: 'Value', name: 'Value' },
  { value: 'Blend', name: 'Blend' },
  { value: 'Growth', name: 'Growth' },
  { value: 'High Growth', name: 'High Growth' },
];

export const marketCapitalizationOptions: Option[] = [
  { value: 'Micro Cap', name: 'Micro Cap' },
  { value: 'Small Cap', name: 'Small Cap' },
  { value: 'Mid Cap', name: 'Mid Cap' },
  { value: 'Large Cap', name: 'Large Cap' },
  { value: 'Mega Cap', name: 'Mega Cap' },
];

export const optionsWithTrend: Option[] = [
  {
    value: 'Sehr gut',
    name: 'Sehr gut',
  },
  { value: 'Gut', name: 'Gut' },
  { value: 'Mittel', name: 'Mittel' },
  { value: 'Schlecht', name: 'Schlecht' },
  {
    value: 'Sehr Schlecht',
    name: 'Sehr Schlecht',
  },
];

export const volumeAbsoluteOptions: Option[] = [
  { value: '50000000', name: '>= 50 Mio. EUR' },
  { value: '100000000', name: '>= 100 Mio. EUR' },
  { value: '500000000', name: '>= 500 Mio. EUR' },
  { value: '1000000000', name: '>= 1 Mrd. EUR' },
  { value: '5000000000', name: '>= 5 Mrd. EUR' },
];

export const trackRecordAbsoluteOptions: Option[] = [
  { value: '1', name: '>= 1 Jahr' },
  { value: '3', name: '>= 3 Jahre' },
  { value: '5', name: '>= 5 Jahre' },
  { value: '7', name: '>= 7 Jahre' },
];

export const mifidOptions: Option[] = [
  { value: 'A', name: 'A' },
  { value: 'O', name: 'O' },
  { value: 'B', name: 'B' },
  { value: 'P', name: 'P' },
  { value: 'Q', name: 'Q' },
  { value: 'R', name: 'R' },
  { value: 'S', name: 'S' },
  { value: 'T', name: 'T' },
  { value: 'U', name: 'U' },
  { value: 'V', name: 'V' },
];
