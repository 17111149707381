import { FC, useMemo, useState } from 'react';
import { StartView } from 'components/User/StartView/StartView';
import { Typography } from 'components/common/Typography/Typography';
import { COLORS } from 'theme/colors';
import { Button } from 'components/common/Buttons/Button';
import { Box } from 'components/common/Box';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppPaths } from '../../../../urls/frontend';
import { ReactComponent as FormSendingConfirmIcon } from 'assets/Icons/forgot-password.svg';
import { requestResetPassword } from 'services/Auth';
import { raiseToast } from 'components/common/Toast/raiseToast';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const ResetPasswordLinkSent: FC = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const email = query.get('email');
  const [resendClicked, setResendClicked] = useState(false);

  const resend = async () => {
    if (email) {
      const response = await requestResetPassword({ email });
      if (response.ok) {
        raiseToast.success(
          'Wir haben Ihnen eine neue E-Mail mit einem Link zum Zurücksetzen Ihres Passworts geschickt.',
        );
        setResendClicked(true);
      }
    }
  };

  return (
    <StartView
      headline="Überprüfen Sie Ihren Posteingang"
      icon={<FormSendingConfirmIcon />}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={3}
        style={{ width: '33%', margin: '25px auto' }}
      >
        <Typography variant="body" color={COLORS.accent.secondary}>
          Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres
          Passworts gesendet
        </Typography>
        <Button fullWidth onClick={() => navigate(AppPaths.login)}>
          Zurück zum Login
        </Button>
      </Box>
      <Box
        display="flex"
        mt={3}
        justifyContent="center"
        alignItems="center"
        gap={0.5}
      >
        <Typography variant="body">E-Mail nicht erhalten?</Typography>
        <Button
          variant={ButtonVariant.TEXT}
          onClick={resend}
          disabled={resendClicked}
        >
          Erneut senden
        </Button>
      </Box>
    </StartView>
  );
};
