import TableRow from '@mui/material/TableRow';
import {
  StyledSpan,
  StyledTableCell,
  StyledTableHead,
  StyledInfoIcon,
  BlankTableCell,
  HighlightedCell,
  StyledIconContainer,
} from './EnhancedTableHead.styled';
import { EnhancedTableHeadProps } from './EnhancedTableHead.d';
import { SelectCell } from '../Row/SelectCell';
import { CustomTooltip } from 'components/common/Tooltip';
import { Box } from 'components/common/Box';
import { COLORS } from 'theme/colors';
import { ReactComponent as UnsortedIcon } from 'assets/Icons/unsorted.svg';
import { ReactComponent as AscendingIcon } from 'assets/Icons/ascending.svg';
import { ReactComponent as DescendingIcon } from 'assets/Icons/descending.svg';
import { useLocation, useSearchParams } from 'react-router-dom';
import { highlightedCellTooltipText } from './config';

export const EnhancedTableHead = ({
  columns,
  rows,
  type,
  sortModel,
  onSortModelChange,
  selectedRows,
  setSelectedRows,
  loading,
  loadingColumnWidth,
}: EnhancedTableHeadProps) => {
  const { search } = useLocation();
  const [, setSearchParams] = useSearchParams();
  const isSelectAll = selectedRows.length !== 0;
  const isSmartBenchmarking = type === 'smartFundBenchmarking';
  const isRecommendationsVariant = type === 'recommendations-for-action';

  const updateSelectedRows = (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();
    setSelectedRows(isSelectAll ? [] : rows.map((row) => row.id));
  };

  const getCellAlign = (
    align: 'center' | 'left' | 'right' | 'justify' | 'inherit',
  ) => {
    switch (align) {
      case 'left':
        return 'flex-start';
      case 'right':
        return 'flex-end';
      default:
        return 'center';
    }
  };

  const handleSortClick = (field: string) => {
    if (sortModel && onSortModelChange && sortModel.sort_by === field) {
      if (sortModel.sort_order === 'asc') {
        const newSearchParams = new URLSearchParams(search);
        newSearchParams.set('sort', `-${field}`);
        newSearchParams.delete('page');
        setSearchParams(newSearchParams);
        onSortModelChange({ sort_by: field, sort_order: 'desc' });
        return;
      }
      if (sortModel.sort_order === 'desc') {
        const newSearchParams = new URLSearchParams(search);
        onSortModelChange(undefined);
        newSearchParams.delete('sort');
        newSearchParams.delete('page');
        setSearchParams(newSearchParams);
        return;
      }
    }
    if (!!onSortModelChange) {
      const newSearchParams = new URLSearchParams(search);
      newSearchParams.set('sort', field);
      newSearchParams.delete('page');
      setSearchParams(newSearchParams);
      onSortModelChange({ sort_by: field, sort_order: 'asc' });
    }
  };

  const getSortIcon = (field: string, color?: string) => {
    if (sortModel && onSortModelChange && sortModel.sort_by === field) {
      if (sortModel.sort_order === 'asc') {
        return <AscendingIcon />;
      }
      if (sortModel.sort_order === 'desc') {
        return <DescendingIcon />;
      }
    }
    return (
      !!onSortModelChange && (
        <UnsortedIcon stroke={color || COLORS.typography.placeholder} />
      )
    );
  };

  const getColumnWidth = (index: number, width?: string) => {
    if (loading && loadingColumnWidth && loadingColumnWidth?.length !== 0) {
      return loadingColumnWidth[index]
        ? `${loadingColumnWidth[index]}px`
        : width;
    }
    return width;
  };

  return (
    <StyledTableHead>
      {(isSmartBenchmarking || isRecommendationsVariant) && (
        <TableRow>
          <BlankTableCell colSpan={5} />
          <HighlightedCell colSpan={5}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1.2}
            >
              Smart Fund Benchmarking
              <CustomTooltip title={highlightedCellTooltipText} placement="top">
                <StyledInfoIcon stroke={COLORS.stroke.main} />
              </CustomTooltip>
            </Box>
          </HighlightedCell>
        </TableRow>
      )}
      <TableRow className="collapse-table-head">
        <StyledTableCell
          width={getColumnWidth(0, '36px')}
          sortable={false}
          style={{
            width: getColumnWidth(0, '36px'),
            minWidth: getColumnWidth(0, '36px'),
            maxWidth: getColumnWidth(0, '36px'),
          }}
        />
        <SelectCell
          variant="table-head"
          isChecked={selectedRows.length === rows.length}
          setIsChecked={updateSelectedRows}
          isSelectAll={isSelectAll && selectedRows.length !== rows.length}
          width={getColumnWidth(1, '25px')}
        />
        {columns.map((headCell, index) => (
          <StyledTableCell
            key={headCell.label}
            sortable={headCell.sortable}
            align={headCell.headAlign}
            width={getColumnWidth(index + 2, headCell.maxWidth)}
            sx={{
              ...(headCell.dottedLeftBorder
                ? { borderLeft: `1px solid ${COLORS.stroke.main}` }
                : {}),
              ...(headCell.isHighlighted
                ? { backgroundColor: COLORS.background.secondary }
                : {}),
              ...(headCell.maxWidth
                ? { width: getColumnWidth(index + 2, headCell.maxWidth) }
                : {}),
              ...(headCell.minWidth
                ? { minWidth: getColumnWidth(index + 2, headCell.minWidth) }
                : {}),
            }}
            onClick={() =>
              headCell.sortable ? handleSortClick(headCell.id) : undefined
            }
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent={getCellAlign(headCell.headAlign)}
              px={0.5}
            >
              {headCell.infoTooltip && (
                <CustomTooltip title={headCell.infoTooltip} placement="top">
                  <StyledInfoIcon
                    stroke={headCell.color || COLORS.typography.placeholder}
                  />
                </CustomTooltip>
              )}
              <StyledSpan
                hasLineBreak={isSmartBenchmarking || isRecommendationsVariant}
                maxTextWidth={headCell.maxTextWidth}
                color={headCell.color}
              >
                {headCell.label}
              </StyledSpan>
              {headCell.sortable && (
                <StyledIconContainer ml={0.5}>
                  {getSortIcon(headCell.id, headCell.color)}
                </StyledIconContainer>
              )}
            </Box>
          </StyledTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};
