import { Box } from 'components/common/Box';
import styled from 'styled-components';

export const StyledContainer = styled(Box)`
  display: flex;
  height: calc(100vh - 69.5px + 89px);
  position: relative;
`;

export const StyledIconContainer = styled(Box)`
  position: absolute;
  bottom: 141px;
  right: 26px;
  width: 60px;
  height: 60px;
`;
