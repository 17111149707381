import { Chip } from '@mui/material';
import { styled as MUIstyled } from '@mui/system';
import { COLORS } from 'theme/colors';
import { StyledChipProps } from './SelectableFilterChip.d';
import styled from 'styled-components';
import { hexToRGB } from 'utils/common';

export const StyledChip = MUIstyled(Chip)<StyledChipProps>(
  ({ selected, withArticle }) => ({
    color: selected ? COLORS.background.secondary : COLORS.typography.chip,
    background: selected ? COLORS.info.selectedBorder : COLORS.accent.disabled,
    height: '14px',
    fontSize: '14px',
    fontFamily: '"Inter", sans-serif',
    padding: '17px 9px',
    overflow: 'hidden',
    borderRadius: 32,
    transitionDuration: `0.2s`,
    border: `1px solid ${
      selected ? COLORS.info.selectedBorder : COLORS.stroke.primary
    }`,
    ...(withArticle ? { flexDirection: 'row-reverse' } : {}),
    '& svg': {
      stroke: selected ? COLORS.background.secondary : COLORS.typography.chip,
    },
    '&:hover': {
      background: selected
        ? COLORS.info.selectedBorder
        : COLORS.background.neutral,
      borderColor: selected
        ? COLORS.info.selectedBorder
        : COLORS.background.neutral,
      color: selected
        ? COLORS.background.secondary
        : COLORS.info.selectedBorder,
      '& svg': {
        stroke: selected
          ? COLORS.background.secondary
          : COLORS.info.selectedBorder,
      },
      ...(withArticle
        ? {
            '& .MuiChip-icon': {
              color: selected
                ? COLORS.info.selectedBorder
                : COLORS.background.secondary,
              backgroundColor: selected
                ? COLORS.background.secondary
                : COLORS.info.selectedBorder,
              borderColor: COLORS.info.selectedBorder,
            },
          }
        : {}),
    },
    '&:active': {
      boxShadow: 'none',
    },

    '.MuiChip-label': {
      padding: '2px 7px !important',
      fontFamily: '"Inter", sans-serif',
      fontWeight: 400,
    },
    '& .MuiChip-icon': {
      marginRight: 2,
      ...(withArticle ? { marginLeft: 1 } : {}),
      color: selected
        ? COLORS.info.selectedBorder
        : COLORS.typography.placeholder,
    },
  }),
);

export const StyledArticleIcon = styled.span<StyledChipProps>`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid
    ${({ selected }) =>
      selected ? COLORS.info.selectedBorder : COLORS.stroke.primary};
  color: ${({ selected }) =>
    selected ? COLORS.info.selectedBorder : COLORS.stroke.primary};
  background-color: ${({ selected }) =>
    selected
      ? COLORS.background.secondary
      : hexToRGB(COLORS.background.secondary, '0.5')};

  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
