import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)`
  padding: 20px;
`;

export const StyledFilterItem = styled(Box)`
  padding: 24px 0;
  border-bottom: 1px solid ${COLORS.stroke.primary};
  display: flex;
  justify-content: center;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &:first-child {
    padding-top: 0;
  }
`;
