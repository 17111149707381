import { handleResponseErrors } from './handleResponseErrors';

export interface ResponseWithBody<T> extends Response {
  parsedBody: T;
}

const fetchAPIWithoutToken = async <T>(url: string, options?: RequestInit) => {
  let headers = { ...options?.headers };

  let response = await fetch(url, {
    ...options,
    headers: headers,
  });

  let parsedResponse = await response.json();

  if (!response.ok) {
    handleResponseErrors(parsedResponse);
  }

  const responseWithBody: ResponseWithBody<T> = {
    ...response,
    ok: response.ok,
    parsedBody: parsedResponse,
    status: response.status,
  };

  return responseWithBody as ResponseWithBody<T>;
};

export default fetchAPIWithoutToken;
