import { Option } from 'components/common/Select/Select.d';
import { TableChipVariant } from 'components/common/TableChip/TableChip.enums';

export const options: Option[] = [
  {
    value: 'Sehr Gut',
    name: 'Sehr Gut',
    chipVariant: TableChipVariant.VIBRANT_GREEN,
  },
  { value: 'Gut', name: 'Gut', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Schlecht', name: 'Schlecht', chipVariant: TableChipVariant.RED },
  {
    value: 'Sehr Schlecht',
    name: 'Sehr Schlecht',
    chipVariant: TableChipVariant.VIBRANT_RED,
  },
];
