import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  styled as MUIstyled,
} from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { StyledFormControlLabelProps } from './CheckboxRegionsFilters.d';

export const StyledTypography = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.info.selectedBorder};
  margin-bottom: 16px;
`;

export const StyledCheckbox = MUIstyled(Checkbox)({
  padding: '0 8px',
});

export const StyledFormControlLabel = MUIstyled(
  FormControlLabel,
)<StyledFormControlLabelProps>(({ isParent }) => ({
  '& .MuiFormControlLabel-label': {
    fontFamily: '"Inter", sans-serif',
    fontSize: '14px',
    lineHeight: '17px',
    color: COLORS.typography.main,
  },
  marginRight: 0,
  '& .Mui-disabled': {
    ...(isParent ? { color: `${COLORS.typography.main} !important` } : {}),
  },
}));

export const StyledTreeItem = MUIstyled(TreeItem)({
  '& .MuiTreeItem-content': {
    '& .Mui-focused': {
      backgroundColor: 'transparent !important',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '& .Mui-focused': {
    backgroundColor: 'transparent !important',
  },
});

export const StyledFormGroup = MUIstyled(FormGroup)(() => ({
  marginLeft: '44px',
  marginTop: '20px',
  margin: '16px 44px 5px',
  gap: '20px',
}));

export const StyledSimpleTreeView = MUIstyled(SimpleTreeView)({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});
