import { Header } from 'components/Modules/Header';
import { Layout } from 'components/common/Layout';
import { FC, useEffect } from 'react';
import { ReactComponent as HeaderIcon } from 'assets/Icons/private-markets-real-estate.svg';
import { Box } from 'components/common/Box';
import { Tabs } from 'components/common/Tabs/Tabs';
import { PrivateMarketsRealEstateTable } from 'components/Modules/Tables/PrivateMarketsRealEstateTable';

export const PrivateMarketsRealEstate: FC = () => {
  const tabs = [
    {
      label: 'Private Markets',
      content: <PrivateMarketsRealEstateTable variant="standart" />,
    },
    // {
    //   label: 'Immobilie',
    //   content: <PrivateMarketsRealEstateTable variant="standart" />,
    // },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout headerVariant="secondary">
      <Box px={2.5} pt={2.3} pb={2.5}>
        <Header
          title="Private Markets & Real Estate"
          descriptions={[
            'Entdecken Sie unsere umfassende Übersicht von Handlungsempfehlungen und Porträts für Private Market Fonds, einschließlich offener Immobilienfonds. Wir decken Infrastruktur Fonds im ELTIF-Mantel oder als OIF sowie Private Equity- und Private Debt Fonds ab. Diese Sammlung wird kontinuierlich aktualisiert und erweitert, wobei alle Handlungsempfehlungen mindestens vierteljährlich überarbeitet werden. Zusätzlich erhalten Sie Zugang zu den detaillierten Reports jedes Fonds.',
          ]}
          icon={<HeaderIcon />}
        />
      </Box>
      <Tabs tabs={tabs} />
    </Layout>
  );
};
