import styled from 'styled-components';
import { styled as MUIStyled, MenuItem, Popper } from '@mui/material';
import { FieldBasic } from 'components/common/Form/common.d';
import { StyledFieldBasic } from 'components/common/Form/common.styled';
import { COLORS } from 'theme/colors';
import { Box } from 'components/common/Box';
import { SIZES } from 'theme/sizes';
import { StyledItemProps } from './ComparisonListSearch.d';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 150px;
  position: relative;
  width: 100%;
`;

export const StyledInput = styled.input<FieldBasic>`
  ${StyledFieldBasic}
  padding-left: 50px;
  padding-right: 144px;

  &:enabled:focus {
    border: 1px solid ${COLORS.typography.placeholder};
  }
`;

export const StyledInputIcon = styled.span<StyledItemProps>`
  bottom: 9px;
  left: 16px;
  position: absolute;

  & {
    svg {
      stroke: ${({ isFocused }) =>
        isFocused ? COLORS.typography.main : COLORS.typography.placeholder};
    }
  }
`;

export const StyledSubmitButtonContainer = styled(Box)`
  position: absolute;
  right: 0;
  top: 0;
  border-left: 1px solid ${COLORS.stroke.primary};

  height: 100%;
  padding-left: ${SIZES.spacing(2)};
  padding-right: ${SIZES.spacing(2)};

  display: flex;
  align-items: center;
  gap: ${SIZES.spacing(1)};

  & span {
    color: ${COLORS.typography.description};
    font-size: 14px;
    line-height: 17px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
  }
`;

export const StyledPlusIconContainer = styled(Box)`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${COLORS.info.selectedBorder};

  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    stroke: ${COLORS.background.secondary};
  }
`;

export const StyledDropdown = MUIStyled(Popper)({
  borderRadius: 4,
  borderColor: COLORS.stroke.primary,
  marginTop: '5px !important',
  backgroundColor: COLORS.background.secondary,
  width: 480,
  maxHeight: 320,
  overflowY: 'auto',
  color: 'rgb(55, 65, 81)',
  boxShadow:
    'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  padding: '16px 8px',
  zIndex: 3,
  boxSizing: 'border-box',
});

export const StyledResultItem = MUIStyled(MenuItem)({
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  color: COLORS.typography.main,
  fontSize: '14px',
  lineHeight: '17px',
  padding: '8px',
  whiteSpace: 'initial',
  '&:hover': {
    backgroundColor: COLORS.background.neutral,
  },
});
