import { Option } from 'components/common/Select/Select.d';
import { TableChipVariant } from 'components/common/TableChip/TableChip.enums';

export const managementResponsibilityOptions: Option[] = [
  { value: 'Lang', name: 'Lang', chipVariant: TableChipVariant.GREEN },
  {
    value: 'Mittel',
    name: 'Mittel',
    chipVariant: TableChipVariant.GREY,
  },
  { value: 'Kurz', name: 'Kurz', chipVariant: TableChipVariant.RED },
];

export const costsSpreadOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch', chipVariant: TableChipVariant.RED },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Niedrig', name: 'Niedrig', chipVariant: TableChipVariant.GREEN },
];

export const averageDailyTradingVolumeOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Niedrig', name: 'Niedrig', chipVariant: TableChipVariant.RED },
];
