import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { COLORS } from 'theme/colors';

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.typography.main,
    borderRadius: 4,
    minHeight: 32,
    height: 'fit-content',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 185,
    textAlign: 'center',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLORS.typography.main,
  },
});
