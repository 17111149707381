import { FC, useEffect, useState } from 'react';
import { ChipFiltersProps } from './ChipFilters.d';
import { Box } from 'components/common/Box';
import { SelectableFilterChip } from 'components/common/Chip/SelectableFilterChip';
import { StyledTypography } from './ChipFilters.styled';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const ChipFilters: FC<ChipFiltersProps> = ({
  id,
  options,
  maxSelected,
}) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterValue = searchParams.getAll(id);
  const [selectedChips, setSelectedChips] = useState<string[]>(filterValue);

  const handleChipClick = (value: string) => {
    if (selectedChips.includes(value.toLowerCase())) {
      setSelectedChips(
        selectedChips.filter(
          (chip) => chip.toLowerCase() !== value.toLowerCase(),
        ),
      );
    } else if (!maxSelected || selectedChips.length < maxSelected) {
      setSelectedChips([...selectedChips, value]);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(search);

    params.delete(id);

    if (!!selectedChips.length) {
      selectedChips.forEach((chip) => params.append(id, chip.toLowerCase()));
    }

    params.delete('page');

    navigate(pathname + '?' + params.toString());
  }, [selectedChips, id]);

  useEffect(() => {
    const newSelectedChips = searchParams.getAll(id);
    setSelectedChips(newSelectedChips);
  }, [searchParams, id]);

  return (
    <>
      {!!maxSelected && (
        <StyledTypography variant="body">{`Wähle maximal ${maxSelected} Optionen`}</StyledTypography>
      )}
      <Box display="flex" gap={1.5} alignItems="center" flexWrap="wrap">
        {options.map(({ value, name }) => (
          <SelectableFilterChip
            key={value}
            label={name}
            selected={selectedChips.includes(value.toLowerCase())}
            onClick={() => handleChipClick(value)}
            disabled={
              selectedChips.length === maxSelected &&
              !selectedChips.includes(value.toLowerCase())
            }
          />
        ))}
      </Box>
    </>
  );
};
