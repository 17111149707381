import { Option } from 'components/common/Select/Select.d';
import { TableChipVariant } from 'components/common/TableChip/TableChip.enums';
import { SFBFiltersProps } from './FCSmartFundBenchmarkingTable.d';
import {
  defaultOptions,
  marketCapitalizationOptions,
  mifidOptions,
  optionsWithTrend,
  trackRecordAbsoluteOptions,
  valueToGrowthAbsOptions,
  volumeAbsoluteOptions,
} from './options.config';
import { CategoryType } from './FCSmartFundBenchmarkingTable.enums';

export const PEER_GROUP = 'peergroups';

export const voteOptions: Option[] = [
  { value: 'buy', name: 'Buy' },
  { value: 'hold', name: 'Hold' },
  { value: 'switch', name: 'Switch' },
];

export const returnOptions: Option[] = [
  {
    value: 'Sehr Gut',
    name: 'Sehr Gut',
    chipVariant: TableChipVariant.VIBRANT_GREEN,
  },
  { value: 'Gut', name: 'Gut', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Schlecht', name: 'Schlecht', chipVariant: TableChipVariant.RED },
  {
    value: 'Sehr Schlecht',
    name: 'Sehr Schlecht',
    chipVariant: TableChipVariant.VIBRANT_RED,
  },
];

export const volumeOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Niedrig', name: 'Niedrig', chipVariant: TableChipVariant.RED },
];

export const netFlows6MonthOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Niedrig', name: 'Niedrig', chipVariant: TableChipVariant.RED },
];

export const trackingErrorOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Niedrig', name: 'Niedrig', chipVariant: TableChipVariant.RED },
];

export const trackRecordOptions: Option[] = [
  { value: 'Lang', name: 'Lang', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Kurz', name: 'Kurz', chipVariant: TableChipVariant.RED },
];

export const riskOptions: Option[] = [
  {
    value: 'Sehr Gut',
    name: 'Sehr Gut',
    chipVariant: TableChipVariant.VIBRANT_GREEN,
  },
  { value: 'Gut', name: 'Gut', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Schlecht', name: 'Schlecht', chipVariant: TableChipVariant.RED },
  {
    value: 'Sehr Schlecht',
    name: 'Sehr Schlecht',
    chipVariant: TableChipVariant.VIBRANT_RED,
  },
];

export const activeManagementOptions: Option[] = [
  {
    value: 'Sehr Gut',
    name: 'Sehr Gut',
    chipVariant: TableChipVariant.VIBRANT_GREEN,
  },
  { value: 'Gut', name: 'Gut', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Schlecht', name: 'Schlecht', chipVariant: TableChipVariant.RED },
  {
    value: 'Sehr Schlecht',
    name: 'Sehr Schlecht',
    chipVariant: TableChipVariant.VIBRANT_RED,
  },
];

export const managementAffiliationOptions: Option[] = [
  { value: 'Lang', name: 'Lang', chipVariant: TableChipVariant.GREEN },
  {
    value: 'Mittel',
    name: 'Mittel',
    chipVariant: TableChipVariant.GREY,
  },
  { value: 'Kurz', name: 'Kurz', chipVariant: TableChipVariant.RED },
];

export const scoreOptions: Option[] = [
  { value: '0-20', name: '0% - 20%' },
  { value: '20-40', name: '>20% - 40%' },
  { value: '40-60', name: '>40% - 60%' },
  { value: '60-80', name: '>60% - 80%' },
  { value: '80-100', name: '>80% - 100%' },
];

export const articleOptions: Option[] = [
  { value: '0-20', name: '0 - 20' },
  { value: '20-40', name: '>20 - 40' },
  { value: '40-60', name: '>40 - 60' },
  { value: '60-80', name: '>60 - 80' },
  { value: '80-100', name: '>80 - 100' },
];

export const SFDROptions: Option[] = [
  { value: '6', name: '6' },
  { value: '8', name: '8' },
  { value: '9', name: '9' },
];

export const assetClassOptions: Option[] = [
  { value: 'Aktien', name: 'Aktien' },
  { value: 'Renten', name: 'Renten' },
  { value: 'Mischfonds', name: 'Mischfonds' },
  { value: 'Alternatives', name: 'Alternatives' },
  { value: 'ETFs', name: 'ETFs' },
];

export const crashDrawdownsOptions: Option[] = [
  {
    value: 'Sehr Gut',
    name: 'Sehr Gut',
    chipVariant: TableChipVariant.VIBRANT_GREEN,
  },
  { value: 'Gut', name: 'Gut', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Schlecht', name: 'Schlecht', chipVariant: TableChipVariant.RED },
  {
    value: 'Sehr Schlecht',
    name: 'Sehr Schlecht',
    chipVariant: TableChipVariant.VIBRANT_RED,
  },
];

export const loyaltyToStyleOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Niedrig', name: 'Niedrig', chipVariant: TableChipVariant.RED },
];

export const assetConcentrationOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch', chipVariant: TableChipVariant.RED },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Niedrig', name: 'Niedrig', chipVariant: TableChipVariant.GREEN },
];

export const trendOptions: Option[] = [
  { value: 'Positive', name: 'Positiver' },
  { value: 'Negative', name: 'Negativer' },
];

export const yesNoOptions: Option[] = [
  { value: 'True', name: 'Ja' },
  { value: 'False', name: 'Nein' },
];

export const rangeOptions: Option[] = [
  { value: '0', name: 'Min' },
  { value: '100', name: 'Max' },
];

export const sektorsOptions: Option[] = [
  { value: 'rohstoffe', name: 'Rohstoffe' },
  { value: 'kommunikationsdienste', name: 'Kommunikationsdienste' },
  { value: 'zyklischer konsum', name: 'Zyklischer Konsum' },
  { value: 'nicht-zyklischer konsum', name: 'Nicht-zyklischer Konsum' },
  { value: 'gesundheitswesen', name: 'Gesundheitswesen' },
  { value: 'industrie', name: 'Industrie' },
  { value: 'immobilien', name: 'Immobilien' },
  { value: 'technologie', name: 'Technologie' },
  { value: 'energie', name: 'Energie' },
  { value: 'finanzen', name: 'Finanzen' },
  { value: 'versorger', name: 'Versorger' },
];

export const indexWeightingOptions: Option[] = [
  { value: 'beta', name: 'Beta' },
  { value: 'dividende', name: 'Dividende' },
  { value: 'duration', name: 'Duration' },
  { value: 'ertrag', name: 'Ertrag' },
  { value: 'fälligkeit', name: 'Fälligkeit' },
  { value: 'fixierte gewichtung', name: 'Fixierte Gewichtung' },
  { value: 'gestaffelt', name: 'Gestaffelt' },
  {
    value: 'gewichtung nach fundamentaldaten',
    name: 'Gewichtung nach Fundamentaldaten',
  },
  { value: 'gewinne', name: 'Gewinne' },
  { value: 'gleichgewichtet', name: 'Gleichgewichtet' },
  { value: 'growth', name: 'Growth' },
  { value: 'liquidität', name: 'Liquidität' },
  { value: 'marktkapitalisierung', name: 'Marktkapitalisierung' },
  {
    value: 'modifizierte marktkapitalisierung',
    name: 'Modifizierte Marktkapitalisierung',
  },
  { value: 'momentum', name: 'Momentum' },
  { value: 'optimierung', name: 'Optimierung' },
  { value: 'produktionsgewichtet', name: 'Produktionsgewichtet' },
  { value: 'proprietär', name: 'Proprietär' },
  { value: 'qualität', name: 'Qualität' },
  { value: 'rückkauf/aktionärsrendite', name: 'Rückkauf/Aktionärsrendite' },
  { value: 'umsatz', name: 'Umsatz' },
  { value: 'unternehmensgröße', name: 'Unternehmensgröße' },
  { value: 'value', name: 'Value' },
  { value: 'volatilität', name: 'Volatilität' },
];

export const peerGroupFilters: SFBFiltersProps[] = [
  {
    categoryName: '',
    categoryType: CategoryType.GENERAL,
    data: [
      {
        innerCategoryName: 'Assetklassen',
        id: 'asset_class',
        options: assetClassOptions,
        type: 'chips',
        maxSelected: 1,
      },
    ],
  },
];

export const sfbFilters: SFBFiltersProps[] = [
  {
    categoryName: 'Generelle Filter',
    categoryType: CategoryType.GENERAL,
    data: [
      {
        innerCategoryName: 'Handlungsempfehlung',
        id: 'vote',
        options: voteOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Fondsvolumen (absoult)',
        id: 'volume_abs_gte',
        options: volumeAbsoluteOptions,
        type: 'chips',
        maxSelected: 1,
      },
      {
        innerCategoryName: 'Fondsvolumen (relativ)',
        id: 'volume',
        options: volumeOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Track Record (absolut)',
        id: 'track_record_abs_gte',
        options: trackRecordAbsoluteOptions,
        type: 'chips',
        maxSelected: 1,
      },
      {
        innerCategoryName: 'Track Record (relativ)',
        id: 'track_record',
        options: trackRecordOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Rendite',
        id: 'return_score',
        options: returnOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Risiko',
        id: 'risk',
        options: riskOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Aktives Management',
        id: 'active_management_score',
        options: activeManagementOptions,
        type: 'chips',
      },

      {
        innerCategoryName: 'Managementzugehörigkeit',
        id: 'management_affiliation',
        options: managementAffiliationOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Tracking error',
        id: 'tracking_error',
        options: trackingErrorOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'SFDR Klassifizierung',
        id: 'sfdr',
        options: SFDROptions,
        type: 'chips-article',
      },
      {
        innerCategoryName: 'MiFID Klassifizierung',
        id: 'mifid',
        options: mifidOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'ESG Credibility Score',
        id: 'credibility',
        options: rangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'ESG Action Score (Gesellsch.)',
        id: 'action_score_firm',
        options: rangeOptions,
        type: 'range',
      },
      {
        innerCategoryName: 'ESG Action Score (Fonds)',
        id: 'action_score_fund',
        options: rangeOptions,
        type: 'range',
      },
    ],
  },
  {
    categoryName: 'Rendite/Risiko im Zeitverlauf',
    categoryType: CategoryType.GENERAL_RETURN_RISK,
    data: [
      {
        innerCategoryName: 'Rendite Kurzfristig',
        id: 'return_short_term',
        options: returnOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Rendite Mittelfristig',
        id: 'return_medium_term',
        options: returnOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Rendite Langfristig',
        id: 'return_long_term',
        options: returnOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Rendite Trend',
        id: 'return_trend',
        options: trendOptions,
        type: 'trend',
      },
      {
        innerCategoryName: 'Max Drawdown Kurzfristig',
        id: 'max_drawdown_short_term',
        options: returnOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Max Drawdown Mittelfristig',
        id: 'max_drawdown_medium_term',
        options: returnOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Max Drawdown Langfristig',
        id: 'max_drawdown_long_term',
        options: returnOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Downside Protection Kurzfristig',
        id: 'drawdown_protection_short_term',
        options: returnOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Downside Protection Mittelfristig',
        id: 'drawdown_protection_medium_term',
        options: returnOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Downside Protection Langfristig',
        id: 'drawdown_protection_long_term',
        options: returnOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Volatilität Kurzfristig',
        id: 'volatility_short_term',
        options: returnOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Volatilität Mittelfristig',
        id: 'volatility_medium_term',
        options: returnOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Volatilität Langfristig',
        id: 'volatility_long_term',
        options: returnOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Volatilität Trend',
        id: 'volatility_trend',
        options: trendOptions,
        type: 'trend',
      },
    ],
  },
  {
    categoryName: 'Aktienfonds',
    categoryType: CategoryType.EQUITY_ASSET_CLASS,
    data: [
      {
        innerCategoryName: 'Übergewichtung Sektor',
        id: 'overweighted_sector_equity',
        options: sektorsOptions,
        type: 'checkboxes',
        maxSelected: 2,
      },
      {
        innerCategoryName: 'Untergewichtung Sektor',
        id: 'underweighted_sector_equity',
        options: sektorsOptions,
        type: 'checkboxes',
        maxSelected: 2,
      },
      {
        innerCategoryName: 'Übergewichtung Länder/Region',
        id: 'overweighted_region_equity',
        options: [],
        type: 'checkboxes-regions',
        maxSelected: 2,
      },
      {
        innerCategoryName: 'Untergewichtung Länder/Region',
        id: 'underweighted_region_equity',
        options: [],
        type: 'checkboxes-regions',
        maxSelected: 2,
      },
      {
        innerCategoryName: 'Anlagestil (Fokus)',
        id: 'value_to_growth_abs_equity',
        options: valueToGrowthAbsOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Stiltreue Anlagestil',
        id: 'value_to_growth_consistency_equity',
        options: loyaltyToStyleOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Marktkapitalisierung (Fokus)',
        id: 'market_capitalization_abs_equity',
        options: marketCapitalizationOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Stiltreue Marktkapitalisierung',
        id: 'market_capitalization_consistency_equity',
        options: loyaltyToStyleOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Assetkonzentration (Top 10)',
        id: 'asset_concentration_equity',
        options: assetConcentrationOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Alpha Kurzfristig',
        id: 'alpha_short_term_equity',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Alpha Mittelfristig',
        id: 'alpha_medium_term_equity',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Alpha Langfristig',
        id: 'alpha_long_term_equity',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Information Ratio Kurzfristig',
        id: 'information_ratio_short_term_equity',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Information Ratio Mittelfristig',
        id: 'information_ratio_medium_term_equity',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Information Ratio Langfristig',
        id: 'information_ratio_long_term_equity',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Alpha Trend',
        id: 'alpha_trend_equity',
        options: trendOptions,
        type: 'trend',
      },
    ],
  },
  {
    categoryName: 'Rentenfonds',
    categoryType: CategoryType.BOND_ASSET_CLASS,
    data: [
      {
        innerCategoryName: 'Durchschn. Kredit-Rating',
        id: 'credit_rating_avg_bond',
        options: defaultOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Rendite auf Verfall (YTM)',
        id: 'yield_to_maturity_bond',
        options: defaultOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Durchschn. Restlaufzeit',
        id: 'remaining_term_avg_bond',
        options: defaultOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Modified Duration',
        id: 'modified_duration_bond',
        options: defaultOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Anteil Staatsanleihen',
        id: 'government_bonds_bond',
        options: defaultOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Anteil Unternehmensanleihen',
        id: 'corporate_bonds_bond',
        options: defaultOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Anteil Verbriefungen',
        id: 'secularizations_bond',
        options: defaultOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Anteil Investment Grade',
        id: 'investment_grade_bond',
        options: defaultOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Anteil High Yield',
        id: 'high_yield_bond',
        options: defaultOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Alpha Kurzfristig',
        id: 'alpha_short_term_bond',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Alpha Mittelfristig',
        id: 'alpha_medium_term_bond',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Alpha Langfristig',
        id: 'alpha_long_term_bond',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Information Ratio Kurzfristig',
        id: 'information_ratio_short_term_bond',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Information Ratio Mittelfristig',
        id: 'information_ratio_medium_term_bond',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Information Ratio Langfristig',
        id: 'information_ratio_long_term_bond',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Alpha Trend',
        id: 'alpa_trend_bond',
        options: trendOptions,
        type: 'trend',
      },
    ],
  },
  {
    categoryName: 'Mischfonds',
    categoryType: CategoryType.MIXED_ASSET_CLASS,
    data: [
      {
        innerCategoryName: 'Aktienquote',
        id: 'equity_ratio_mixed',
        options: defaultOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Übergewichtung Sektor',
        id: 'overweighted_sector_mixed',
        options: sektorsOptions,
        type: 'checkboxes',
        maxSelected: 2,
      },
      {
        innerCategoryName: 'Untergewichtung Sektor',
        id: 'underweighted_sector_mixed',
        options: sektorsOptions,
        type: 'checkboxes',
        maxSelected: 2,
      },
      {
        innerCategoryName: 'Übergewichtung Länder/Region',
        id: 'overweighted_region_mixed',
        options: [],
        type: 'checkboxes-regions',
        maxSelected: 2,
      },
      {
        innerCategoryName: 'Untergewichtung Länder/Region',
        id: 'underweighted_region_mixed',
        options: [],
        type: 'checkboxes-regions',
        maxSelected: 2,
      },
      {
        innerCategoryName: 'Anlagestil (Fokus)',
        id: 'value_to_growth_abs_mixed',
        options: valueToGrowthAbsOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Stiltreue Anlagestil',
        id: 'value_to_growth_consistency_mixed',
        options: loyaltyToStyleOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Marktkapitalisierung (Fokus)',
        id: 'market_capitalization_abs_mixed',
        options: marketCapitalizationOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Stiltreue Marktkapitalisierung',
        id: 'market_capitalization_consistency_mixed',
        options: loyaltyToStyleOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Assetkonzentration (Top 10)',
        id: 'asset_concentration_mixed',
        options: assetConcentrationOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Durchschn. Kredit-Rating',
        id: 'credit_rating_avg_mixed',
        options: defaultOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Rendite auf Verfall (YTM)',
        id: 'yield_to_maturity_mixed',
        options: defaultOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Durchschn. Restlaufzeit',
        id: 'remaining_term_avg_mixed',
        options: defaultOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Modified Duration',
        id: 'modified_duration_mixed',
        options: defaultOptions,
        type: 'chips',
      },
    ],
  },
  {
    categoryName: 'Alternatives',
    categoryType: CategoryType.ALTERNATIVE_ASSET_CLASS,
    data: [
      {
        innerCategoryName: 'Calmar-Ratio Kurzfristig',
        id: 'calmar_ration_short_term',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Calmar-Ratio Mittelfristig',
        id: 'calmar_ration_medium_term',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Calmar-Ratio Langfristig',
        id: 'calmar_ration_long_term',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Gain/Loss Ratio Kurzfristig',
        id: 'gain_loss_ratio_short_term',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Gain/Loss Ratio Mittelfristig',
        id: 'gain_loss_ratio_medium_term',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Gain/Loss Ratio Langfristig',
        id: 'gain_loss_ratio_long_term',
        options: optionsWithTrend,
        type: 'chips',
      },
      {
        innerCategoryName: 'Gain/Loss-Ratio Trend',
        id: 'gain_loss_ratio_trend',
        options: trendOptions,
        type: 'trend',
      },
    ],
  },
  {
    categoryName: 'ETFs',
    categoryType: CategoryType.ETF_ASSET_CLASS,
    data: [
      {
        innerCategoryName: 'Benchmark',
        id: 'benchmark',
        options: [],
        type: 'search',
      },
      {
        innerCategoryName: 'Indexgewichtung',
        id: 'index_weighting',
        options: indexWeightingOptions,
        type: 'checkboxes',
      },
      {
        innerCategoryName: 'Wertpapierleihe',
        id: 'securities_lending',
        options: yesNoOptions,
        type: 'yes-no',
      },
      {
        innerCategoryName: 'Aktives Management',
        id: 'active_management',
        options: yesNoOptions,
        type: 'yes-no',
      },
      {
        innerCategoryName: 'Physische Replikation',
        id: 'physical_replication',
        options: yesNoOptions,
        type: 'yes-no',
      },
      {
        innerCategoryName: 'Spread',
        id: 'spread',
        options: defaultOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Kosten',
        id: 'costs',
        options: defaultOptions,
        type: 'chips',
      },
      {
        innerCategoryName: 'Tägliches Handelsvolumen',
        id: 'daily_trading_volume_avg',
        options: defaultOptions,
        type: 'chips',
      },
    ],
  },
];
