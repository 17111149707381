import { forwardRef } from 'react';
import { Modal } from 'components/common/Modal/Modal';
import { StyledContainer } from './RegistrationRequestModal.styled';
import { RegistrationRequestModalProps } from './RegistrationRequestModal.d';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { RegistrationContactUsForm } from 'components/ContactUs/RegistrationContactUsForm';

export const RegistrationRequestModal = forwardRef<
  HTMLDivElement,
  RegistrationRequestModalProps
>(({ isShown, toggle }, ref) => {
  return (
    <Modal
      modalRef={ref}
      isShown={isShown}
      onClose={toggle}
      withCloseButton
      title="Vielen Dank für Ihre Anfrage"
      px={4}
      pb={5}
      pt={4}
    >
      <StyledContainer pt={2.5} pb={4}>
        <Typography variant="body" color={COLORS.typography.description}>
          Bitte füllen Sie das Kontaktformular aus, und wir werden uns so
          schnell wie möglich bei Ihnen melden.
        </Typography>
        <RegistrationContactUsForm closeForm={toggle} />
      </StyledContainer>
    </Modal>
  );
});
