import { FC } from 'react';
import { Box } from '../Box';
import { Typography } from '../Typography';
import { StyledLink } from './Footer.styled';
import { AppPaths } from 'urls/frontend';

const footerLinks = [
  { link: AppPaths.termsAndConditions, label: 'Nutzungsbedingungen', id: '1' },
  {
    link: AppPaths.privacyPolicy,
    label: 'Datenschutzerklärung nach DSGVO',
    id: '2',
  },
];

export const Footer: FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      px={8}
      py={5}
    >
      <Typography variant="body">© 2024 FondConsult Research GmBH</Typography>
      <Box display="flex" gap={3}>
        {footerLinks.map((footerLink) => (
          <StyledLink key={footerLink.id} href={footerLink.link}>
            {footerLink.label}
          </StyledLink>
        ))}
      </Box>
    </Box>
  );
};
