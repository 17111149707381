import { FC, useEffect } from 'react';
import { Footer } from 'components/common/Footer';
import {
  StyledHeader,
  StyledItem,
  StyledItemsList,
  StyledLink,
} from './TermsAndConditionsPage.styled';
import { Box } from 'components/common/Box';
import { Layout } from 'components/common/Layout';

export const TermsAndConditionsPage: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout headerVariant="secondary">
      <Box pt={2.5} pb={5}>
        <Box
          pt={3}
          pb={3}
          pl={8}
          pr={30}
          display="flex"
          flexDirection="column"
          gap={4}
        >
          <Box>
            <StyledHeader variant="h1">Nutzungsbedingungen</StyledHeader>
            <StyledItemsList>
              <StyledItem>
                Anwendungsbereich
                <ol>
                  <StyledItem>
                    Diese Nutzungsbedingungen gelten für die Registrierung und
                    die Nutzung des nur nach Registrierung zugänglichen Bereichs
                    des über die Internetseite{' '}
                    <StyledLink
                      href="https://www.fondsconsult.de/"
                      target="_blank"
                    >
                      www.fondsconsult.de
                    </StyledLink>{' '}
                    erreichbaren Informationsportals{' '}
                    <span>„FondsConsult Plattform“</span>.
                  </StyledItem>
                  <StyledItem>
                    Nutzer der FondsConsult Plattform im Sinne dieser
                    Nutzungsbedingungen sind ausschließlich professionelle
                    Nutzer, deren Registrierung bestätigt wurde (auch
                    <span>,,Kunden‘‘</span>). Eine Nutzung durch private Nutzer
                    ist ausgeschlossen.
                  </StyledItem>
                  <StyledItem>
                    Die FondsConsult Plattform wird durch die FondsConsult
                    Resarch GmbH, Seidlstr. 26, 80335 München (
                    <span>„FondsConsult“</span>) betrieben. Diese ist erreichbar
                    telefonisch unter{' '}
                    <StyledLink href="tel:+4989244180230">
                      +49 (89) 24 41 80230
                    </StyledLink>{' '}
                    oder per E-Mail an{' '}
                    <StyledLink href="mailto:research@fondsconsult.de">
                      resarch@fondsconsult.de
                    </StyledLink>
                    .
                  </StyledItem>
                  <StyledItem>
                    Andere Geschäftsbedingungen finden keine Anwendung, auch
                    wenn FondsConsult ihrer Geltung im Einzelfall nicht
                    gesondert widerspricht. Selbst wenn FondsConsult auf ein
                    Schreiben Bezug nimmt, das andere Geschäftsbedingungen
                    enthält oder auf solche verweist, liegt darin kein
                    Einverständnis mit der Geltung jener Geschäftsbedingungen.
                  </StyledItem>
                  <StyledItem>
                    Die FondsConsult Plattform richtet sich an professionelle
                    Nutzer mit Sitz in der Bundesrepublik Deutschland.
                  </StyledItem>
                </ol>
              </StyledItem>

              <StyledItem>
                Registrierung
                <ol>
                  <StyledItem>
                    Bei der Registrierung sind Vor- und Nachname, eine gültige
                    E-Mail-Adresse und ein Passwort anzugeben. Zudem ist eine
                    Telefon- oder Mobilnummer, das Unternehmen sowie die
                    Funktion/ Tätigkeitsbereich des Nutzers anzugeben.
                  </StyledItem>
                  <StyledItem>
                    Voraussetzung für die erfolgreiche Registrierung ist die
                    Verifikation der angegebenen E-Mail-Adresse durch Klick auf
                    den entsprechenden Link in der versendeten
                    Bestätigungs-E-Mail.
                  </StyledItem>
                  <StyledItem>
                    FondsConsult ist berechtigt, die bereitgestellten
                    Informationen auf Vollständigkeit und Plausibilität zu
                    prüfen. Die Registrierung wird erst wirksam, wenn diese
                    durch FondsConsult bestätigt worden ist. Die Bestätigung der
                    Registrierung steht im freien Ermessen von FondsConsult.
                  </StyledItem>
                  <StyledItem>
                    Die Zugangsdaten einschließlich des Passworts sind
                    vertraulich zu behandeln und dürfen unbefugten Dritten nicht
                    zugänglich gemacht werden. Besteht Grund zur Annahme, dass
                    Dritte unbefugt Kenntnis von den Zugangsdaten erhalten haben
                    oder erlangen werden, ist FondsConsult unverzüglich hierüber
                    informieren.
                  </StyledItem>
                </ol>
              </StyledItem>

              <StyledItem>
                Gebühren und Zahlung
                <ol>
                  <StyledItem>
                    Die Registrierung und Nutzung der FondsConsult Plattform ist
                    kostenlos. FondsConsult behält sich das Recht vor, für die
                    Zukunft ein Entgelt für die Nutzung der FondsConsult
                    Plattform zu erheben, sofern FondsConsult den Kunden
                    rechtzeitig darüber informiert.
                  </StyledItem>
                  <StyledItem>
                    Die FondsConsult Plattform bietet Zugang zu einigen
                    Produkten ohne zusätzliche Kosten. Für den Zugriff auf
                    bestimmte Produkte ist jedoch ein Honorar zu entrichten,
                    einschließlich jährlicher Honorare für Abonnementprodukte.
                    Diese Honorare sind beim Kauf der betreffenden Produkte
                    fällig, sofern in den geltenden Produktbedingungen oder auf
                    der Rechnung nichts anderes bestimmt ist.
                  </StyledItem>
                  <StyledItem>
                    FondsConsult erhebt und zieht alle Honorare für Produkte
                    ein, die auf der FondsConsult Plattform angeboten werden,
                    einschließlich der von Dritten angebotenen Produkte. Die
                    Rechnungen für alle Produkte werden auf den Kunden
                    ausgestellt und sind vom Kunden zu bezahlen. FondsConsult
                    zieht die Zahlungen für alle Produkte von Drittanbietern im
                    Namen der Dritten ein.
                  </StyledItem>
                  <StyledItem>
                    Die auf der FondsConsult Plattform genannten Honorare und
                    Preise verstehen sich zuzüglich Mehrwertsteuer. Im
                    Zusammenhang mit der Zahlung von Gebühren wird kein Abzug
                    vorgenommen.
                  </StyledItem>
                  <StyledItem>
                    Für alle Honorare, die nicht innerhalb der für die Zahlung
                    vorgesehenen Frist eingegangen sind, werden ab dem
                    Fälligkeitsdatum bis zum Datum der tatsächlichen Zahlung
                    sowohl nach als auch vor dem Urteil Zinsen auf den
                    überfälligen Betrag in Höhe des nach geltendem Recht maximal
                    zulässigen Zinssatzes berechnet.
                  </StyledItem>
                  <StyledItem>
                    Der Kunde erkennt an und stimmt zu, dass FondsConsult das
                    Recht hat, die Zahlung im Namen von Drittanbietern von
                    Produkten durchzusetzen.
                  </StyledItem>
                  <StyledItem>
                    Die Honorare für Produkte können sich ändern. FondsConsult
                    wird den Kunden benachrichtigen, wenn sich die Gebühren für
                    Produkte erhöhen, und gegebenenfalls die Zustimmung des
                    Kunden einholen, um das Produkt fortzusetzen.
                  </StyledItem>
                  <StyledItem>
                    Bestimmte kostenpflichtige Produkte können eine kostenlose
                    Testphase beinhalten, bevor sie dem Kunden in Rechnung
                    gestellt werden. Die kostenlose Testphase kann entweder
                    automatisch enden und einen Kauf für den fortgesetzten
                    Zugriff auf das Produkt erfordern oder den Kunden
                    verpflichten, die kostenlose Testversion vor Ablauf zu
                    beenden, um den Kauf des Produkts zu vermeiden. Der
                    Geltungsbereich gibt die Art und Weise der Beendigung im
                    Zusammenhang mit einer solchen kostenlosen Testversion an.
                  </StyledItem>
                </ol>
              </StyledItem>

              <StyledItem>
                Leistungen von FondsConsult
                <ol>
                  <StyledItem>
                    Kunden haben die Möglichkeit, sog. Ratings von
                    Investmentfonds und weitere Informationen von FondsConsult
                    zu den Fonds einzusehen.
                  </StyledItem>
                  <StyledItem>
                    Ferner erhalten Kunden Zugriff auf eine so genannte „Meine
                    Fondsliste“ („Watchlist“). Durch die Watchlist kann der
                    Kunde selbst ausgewählte Fonds in einer Liste speichern und
                    sich über Ratingänderungen informieren.
                  </StyledItem>
                  <StyledItem>
                    FondsConsult analysiert die Nutzung von der FondsConsult
                    Plattform durch den Kunden und zeigt ihm auf dieser
                    Grundlage insbesondere solche Informationen an, die
                    besonders interessant für ihn sind. Voraussetzung für die
                    Inanspruchnahme dieser Analyse ist, dass der Kunde in den
                    Einsatz bestimmter Analyse-Cookies gegebenenfalls
                    einwilligt.
                  </StyledItem>
                  <StyledItem>
                    FondsConsult wird angemessene Anstrengungen unternehmen, um
                    die Verfügbarkeit des Portals aufrechtzuerhalten. Der Kunde
                    erkennt an, dass FondsConsult keinen definierten Grad an
                    Verfügbarkeit oder die Einhaltung eines Service Levels
                    schuldet.
                  </StyledItem>
                  <StyledItem>
                    FondsConsult garantiert nicht, dass das Portal fehlerfrei
                    genutzt werden kann und/oder dass es frei von Viren und
                    anderen schädlichen Komponenten ist.
                  </StyledItem>
                </ol>
              </StyledItem>

              <StyledItem>
                Ausschlüsse und Disclaimer
                <ol>
                  <StyledItem>
                    Eine Garantie für Richtigkeit, Aktualität, Eignung für einen
                    bestimmten Zweck und Vollständigkeit der Inhalte wird von
                    FondsConsult nicht übernommen.
                  </StyledItem>
                  <StyledItem>
                    FondsConsult erbringt keine Rechtsberatungs-,
                    Steuerberatungs-, Beratungs-, oder
                    Unternehmensdienstleistungen.
                  </StyledItem>
                  <StyledItem>
                    Über die FondsConsult Plattform werden keine
                    Finanzdienstleistungen erbracht. Das Portal dient
                    ausschließlich der Bereitstellung von Informationen.
                    FondsConsult bietet keine Beratung bei der Vermittlung
                    und/oder Verwaltung von Finanzinstrumenten an.
                  </StyledItem>
                  <StyledItem>
                    Die angebotenen Fondsratings stellen kein Rating im Sinne
                    der Verordnung (EG) Nr. 1060/2009 über Ratingagenturen in
                    der Fassung der Änderungsverordnungen (EU) Nr. 513/2011 und
                    (EU) Nr. 462/2013 dar. Die von FondsConsult zur Verfügung
                    gestellten Fondsratings sind Anlageempfehlungen. Diese
                    enthalten aber keine Anlageempfehlungen oder Anlageberatung,
                    da sie keine Beurteilung des Wertes des Finanzinstruments
                    enthalten und auch den Anlagehorizont, die individuellen
                    Anlageziele oder die Finanzlage einzelner Anleger nicht
                    berücksichtigen.
                  </StyledItem>
                  <StyledItem>
                    Die FondsConsult Plattform enthält keine Kauf- und/oder
                    Verkaufsangebote für Finanzinstrumente. Insbesondere sind
                    alle Inhalte zu Fonds, Wertpapieren und/oder anderen
                    Finanzinstrumenten nicht als individuelle Anlageempfehlungen
                    für Finanzinstrumente zu verstehen.
                  </StyledItem>
                  <StyledItem>
                    Verweise und Links auf Websites Dritter bedeuten nicht, dass
                    sich FondsConsult die hinter dem Verweis oder Link stehenden
                    Inhalte zu eigen macht. Die Inhalte begründen keine
                    Verantwortung von FondsConsult für die dort bereitgestellten
                    Daten und Informationen. FondsConsult hat keinen Einfluss
                    auf die Inhalte hinter dem Link. Für illegale, fehlerhafte
                    oder unvollständige Inhalte und für Schäden, die durch die
                    Nutzung der verlinkten Inhalte entstehen, haftet
                    FondsConsult nicht.
                  </StyledItem>
                </ol>
              </StyledItem>

              <StyledItem>
                Änderungen dieser Nutzungsbedingungen
                <ol>
                  <StyledItem>
                    FondsConsult kann von Zeit zu Zeit redaktionelle Änderungen
                    an diesen Nutzungsbedingungen vornehmen, die das laufende
                    Vertragsverhältnis mit FondsConsult nicht betreffen. Zu
                    diesen Änderungen gehören beispielsweise die Korrektur von
                    Tippfehlern oder die Aktualisierung von
                    Kontaktinformationen.
                  </StyledItem>
                  <StyledItem>
                    FondsConsult ist darüber hinaus berechtigt, gelegentlich und
                    nach eigenem Ermessen Änderungen an diesen
                    Nutzungsbedingungen aus triftigen Gründen vorzunehmen, die
                    das laufende Vertragsverhältnis betreffen, insbesondere
                    aufgrund neuer technischer Entwicklungen, rechtlicher
                    Änderungen, einer Anordnung oder eines Antrags einer
                    Regulierungsbehörde, einer Gerichtsentscheidung oder anderer
                    gleichwertiger Gründe wie Änderungen in der Vertriebs- oder
                    Produktpolitik.
                  </StyledItem>
                  <StyledItem>
                    Will FondsConsult solche Änderungen vornehmen, die das
                    aktuelle Vertragsverhältnis mit dem Kunden betreffen, wird
                    FondsConsult den Kunden spätestens zwei Monate vor dem
                    vorgeschlagenen Inkrafttreten in auffälliger und
                    angemessener Weise informieren. Dies kann eine auffällige
                    Meldung im Portal, d.h. beim nächsten Login, oder eine
                    E-Mail sein. Diese Nachricht enthält Informationen über die
                    von FondsConsult gewünschten Änderungen, das Recht des
                    Kunden, sie abzulehnen, und die Folgen, wenn der Kunde sie
                    nicht vor Ablauf der Frist ablehnt. Die Änderungen gelten
                    als genehmigt, wenn der Kunde entweder direkt zustimmt oder
                    wenn er die Änderungen nicht vor Ablauf der Frist ablehnt.
                    Wenn der Kunde dem Änderungsvorschlag widerspricht, kann
                    jede Partei die von der mitgeteilten Änderung betroffene
                    Vereinbarung vor dem vorgeschlagenen Inkrafttreten mit
                    sofortiger Wirkung und ohne zusätzliche Kosten kündigen. In
                    der Mitteilung wird FondsConsult die von der vorgeschlagenen
                    Änderung betroffene Vereinbarung identifizieren und den
                    Kunden ausdrücklich auf dieses Kündigungsrecht hinweisen.
                    Wird der Vertrag gekündigt, unterliegen Verträge mit
                    FondsConsult, die nicht von der vorgeschlagenen Änderung
                    betroffen sind, nicht diesem Kündigungsrecht.
                  </StyledItem>
                </ol>
              </StyledItem>

              <StyledItem>
                Sperrung des Zugangs zum Portal
                <p>
                  FondsConsult kann den Zugang zum Portal vorübergehend oder
                  dauerhaft sperren, wenn es konkrete Anhaltspunkte dafür gibt,
                  dass der Kunde gegen diese Nutzungsbedingungen und/oder
                  geltendes Recht verstoßen hat, oder wenn eine Sperrung
                  erforderlich ist, um die IT-Sicherheit oder andere berechtigte
                  Interessen von FondsConsult zu wahren. FondsConsult wird bei
                  der Entscheidung über die Sperrung die berechtigten Interessen
                  des Kunden so weit wie möglich und zumutbar berücksichtigen.
                </p>
              </StyledItem>

              <StyledItem>
                Kündigung und Verbraucher-Widerrufsrecht
                <ol>
                  <StyledItem>
                    Der Vertrag über die Nutzung der FondsConsult Plattform
                    unter Geltung dieser Nutzungsbedingungen wird auf
                    unbestimmte Zeit geschlossen und ist durch beide Parteien
                    mit einer Frist von vier Wochen zum jeweiligen Monatsende
                    kündbar. Hiervon unberührt bleibt das Recht beider Parteien
                    zur sofortigen Kündigung aus wichtigem Grund.
                  </StyledItem>
                  <StyledItem>
                    Die Erklärung der Kündigung hat in Textform zu erfolgen.
                  </StyledItem>
                  <StyledItem>
                    Die Beendigung des Vertrages über die Nutzung der
                    FondsConsult Plattform berührt nicht bestehende sonstige
                    Vereinbarungen über Dienstleistungen und Produkte mit
                    FondsConsult.
                  </StyledItem>
                </ol>
              </StyledItem>

              <StyledItem>
                Nutzungsrecht
                <ol>
                  <StyledItem>
                    Für die Dauer des Vertrages über die Nutzung der
                    FondsConsult Plattform gewährt FondsConsult dem Kunden ein
                    nicht ausschließliches, geografisch unbegrenztes, nicht
                    unterlizenzierbares und nicht übertragbares Recht, auf die
                    auf der FondsConsult Plattform verfügbaren Inhalte
                    zuzugreifen und diese nur für den eigenen Gebrauch
                    anzuzeigen.
                  </StyledItem>
                  <StyledItem>
                    Der Kunde ist nur dann zum Herunterladen und Ausdrucken von
                    Inhalten berechtigt, wenn auf dem Portal eine Möglichkeit
                    zum Herunterladen oder Ausdrucken als Funktion zur Verfügung
                    steht (z. B. über einen Download-Button).
                  </StyledItem>
                </ol>
              </StyledItem>

              <StyledItem>
                Haftungsbeschränkung
                <ol>
                  <StyledItem>
                    FondsConsult haftet unbeschränkt, wenn die Schadensursache
                    auf Vorsatz oder grober Fahrlässigkeit beruht. Des Weiteren
                    haftet FondsConsult für die leicht fahrlässige Verletzung
                    wesentlicher Pflichten, deren Verletzung die Erreichung des
                    Vertragszwecks gefährdet, oder für die Verletzung von
                    Pflichten, deren Erfüllung die ordnungsgemäße Durchführung
                    es Vertrages erst ermöglicht und auf deren Einhaltung sich
                    die Vertragsparteien regelmäßig verlassen. In diesem Fall
                    haftet FondsConsult jedoch nur für den vorhersehbaren,
                    vertragstypischen Schaden. FondsConsult haftet nicht für
                    leicht fahrlässige Verletzungen anderer als der in den
                    vorstehenden Sätzen genannten Verpflichtungen.
                  </StyledItem>
                  <StyledItem>
                    Die vorstehenden Haftungsbeschränkungen gelten nicht für
                    Verletzungen von Leben, Körper oder Gesundheit, für
                    arglistig verschwiegene Mängel und für Mängel bezüglich
                    derer eine Beschaffenheitsgarantie übernommen wurde. Die
                    Haftung nach dem Produkthaftungsgesetz bleibt unberührt.
                  </StyledItem>
                  <StyledItem>
                    Soweit die Haftung von FondsConsult ausgeschlossen oder
                    beschränkt ist, gilt dies auch für die persönliche Haftung
                    von Arbeitnehmern, gesetzlichen Vertretern und
                    Erfüllungsgehilfen von FondsConsult.
                  </StyledItem>
                  <StyledItem>
                    Sollte dieser Vertrag ausnahmsweise eine Schutzwirkung
                    zugunsten Dritter entfalten, gelten die vorstehenden
                    Haftungsbeschränkungen auch gegenüber Dritten.
                  </StyledItem>
                </ol>
              </StyledItem>

              <StyledItem>
                Richtlinien / Schlussbestimmungen
                <ol>
                  <StyledItem>
                    Diese Nutzungsbedingungen sind in deutscher Sprache
                    verfasst. Übersetzungen dieser Nutzungsbedingungen in andere
                    Sprachen dienen ausschließlich Informationszwecken. Im Falle
                    von Widersprüchen zwischen der deutschen Version und
                    Übersetzungen geht die deutsche Version vor.
                  </StyledItem>
                  <StyledItem>
                    Es gilt deutsches Recht unter Ausschluss des Übereinkommens
                    der Vereinten Nationen über Verträge über den
                    internationalen Warenkauf (CISG) und des deutschen
                    Kollisionsrechts. Ist der Kunde Verbraucher und hat er
                    seinen gewöhnlichen Aufenthalt in einem Mitgliedstaat der
                    Europäischen Union, so gilt ebenfalls deutsches Recht, wobei
                    zwingende Bestimmungen des Staates, in dem der Nutzer seinen
                    gewöhnlichen Aufenthalt hat, unberührt bleiben.
                  </StyledItem>
                  <StyledItem>
                    Ist der Kunde Kaufmann oder eine juristische Person des
                    öffentlichen Rechts oder ein Sondervermögen des öffentlichen
                    Rechts oder hat er keinen allgemeinen Gerichtsstand in
                    Deutschland, ist ausschließlicher Gerichtsstand für alle
                    Streitigkeiten aus oder im Zusammenhang mit diesen
                    Nutzungsbedingungen München.
                  </StyledItem>
                  <StyledItem>
                    Die Europäische Kommission bietet eine Plattform für die
                    außergerichtliche Online-Streitbeilegung (OS-Plattform), die
                    unter{' '}
                    <StyledLink
                      href="https://ec.europa.eu/consumers/odr"
                      target="_blank"
                    >
                      www.ec.europa.eu/consumers/odr
                    </StyledLink>{' '}
                    zugänglich ist. Unsere E-Mail-Adresse finden Sie in unserem
                    Impressum. Wir sind weder verpflichtet noch bereit, uns an
                    dem Streitbeilegungsverfahren zu beteiligen.
                  </StyledItem>
                  <StyledItem>
                    Der Kunde ist nicht berechtigt, ohne vorherige schriftliche
                    Zustimmung von FondsConsult Rechte oder Ansprüche aus oder
                    im Zusammenhang mit der Nutzung der FondsConsult Plattform,
                    eines über die FondsConsult Plattform geschlossenen
                    Vertrages und/oder dieser Nutzungsbedingungen an Dritte
                    abzutreten.
                  </StyledItem>
                  <StyledItem>
                    Sollte eine einzelne Bestimmung dieser Nutzungsbedingungen
                    ganz oder teilweise unwirksam sein, so wird dadurch die
                    Gültigkeit der übrigen Bestimmungen nicht berührt.
                  </StyledItem>
                  <StyledItem>
                    Sofern in diesen Nutzungsbedingungen nichts anderes bestimmt
                    ist, sind Änderungen oder Ergänzungen dieser
                    Nutzungsbedingungen nur gültig, wenn sie in Textform
                    erfolgen. Dies gilt auch für jede Änderung oder Aufhebung
                    dieser Bestimmung.
                  </StyledItem>
                </ol>
              </StyledItem>
            </StyledItemsList>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Layout>
  );
};
