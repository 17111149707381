import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-top: 24px;
`;

export const StyledLink = styled(Link)`
  color: ${COLORS.typography.body};
  transition-duration: 0.2s;

  &:hover {
    color: ${COLORS.accent.primary};
    text-decoration: none;
  }
`;
