import { Box } from 'components/common/Box';
import { Button } from 'components/common/Buttons/Button';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.background.header};
  border-radius: 16px;

  &:hover {
    svg {
      stroke: ${COLORS.accent.hover};
    }
  }
`;

export const StyledDescriptionContainer = styled(Box)`
  width: 73%;
`;

export const StyledShowMoreButton = styled(Button)`
  &:enabled {
    &:hover {
      & div {
        & svg {
          stroke: ${COLORS.info.selectedBorder};
        }
      }
    }
  }
`;
