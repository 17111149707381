export const periods = [
  {
    columnName: 'Kurzfristig',
    tooltipInfo:
      'Mittelwert aus dem SFB-Scoring für die verschiedenen Risikokennzahlen über sechs Monate und ein Jahr, sofern vorhanden. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
  },
  {
    columnName: 'Mittelfristig',
    tooltipInfo:
      'Mittelwert aus dem SFB-Scoring für die verschiedenen Risikokennzahlen über drei Jahre und fünf Jahre, sofern vorhanden. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
  },
  {
    columnName: 'Langfristig',
    tooltipInfo:
      'Mittelwert aus dem SFB-Scoring für die verschiedenen Risikokennzahlen über sieben Jahre und zehn Jahre, sofern vorhanden. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
  },
];

export const trendTooltip =
  'Trendpfeil signalisiert, wie sich die Positionierung eines Fonds innerhalb seiner Vergleichsgruppe bezüglich der eingegangenen Volatilität im Zeitverlauf geändert hat. Ein positiver Trend signalisiert eine relativ gesehen niedrigere Volatilität. (Mögliche Bewertungen: Positiver Trend, Gleichbleibender Trend, Negativer Trend, Kein Trend erkennbar).';

export const headTooltip =
  'Mit unserem innovativen Smart Fund Benchmarking Ansatz bewerten wir alle in Deutschland zum Vertrieb zugelassenen Fonds bezüglich ihres Ranges innerhalb ihrer Vergleichsgruppe nicht nur anhand quantitativer sondern auch qualitativer Daten. So erhalten Sie einen schnellen Überblick über die Eigenschaften und Alleinstellungsmerkmale verschiedener Produkte.';
