import { FC, useCallback, useEffect, useState } from 'react';
import { SearchProps } from './Search.d';
import { StyledContainer, StyledInputIcon, StyledInput } from './Search.styled';
import { ReactComponent as SearchIcon } from 'assets/Icons/search.svg';
import { debounce } from 'utils/common';
import { COLORS } from 'theme/colors';

export const Search: FC<SearchProps> = ({
  onSubmit,
  initialValue,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState<string>(initialValue);

  const handleSearch = (value: string) => {
    onSubmit && onSubmit(value);
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

  useEffect(() => {
    debouncedHandleSearch(inputValue);
  }, [inputValue, debouncedHandleSearch]);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  return (
    <StyledContainer>
      <StyledInputIcon onClick={() => onSubmit && onSubmit(initialValue)}>
        <SearchIcon stroke={COLORS.typography.placeholder} />
      </StyledInputIcon>
      <StyledInput
        placeholder={placeholder}
        onChange={(e) => setInputValue(e.target.value)}
        value={inputValue}
      />
    </StyledContainer>
  );
};
