import { Typography } from 'components/common/Typography';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledContent = styled(Typography)`
  font-size: 16px;
  line-height: 29px;

  & span {
    font-weight: 600;
    color: ${COLORS.typography.main};
  }
`;

export const StyledHeader = styled(Typography)`
  font-size: 36px;
  margin-bottom: 16px;
`;

const linkStyles = css`
  color: ${COLORS.typography.body};
  transition-duration: 0.2s;

  &:hover {
    color: ${COLORS.accent.primary};
    text-decoration: none;
  }
`;

export const StyledInnerLink = styled(Link)`
  ${linkStyles}
`;

export const StyledLink = styled.a`
  ${linkStyles}
`;

export const StyledItemsList = styled.ol`
  padding-inline-start: 33px;
  margin: 0;
`;

export const StyledItem = styled.li`
  font-size: 16px;
  line-height: 29px;
  font-family: 'Inter', sans-serif;
  color: ${COLORS.typography.description};

  padding-left: 8px;
  margin-bottom: 10px;
`;
