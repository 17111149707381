import {
  InputLabel,
  styled as MUIstyled,
  Slider,
  TextField,
  outlinedInputClasses,
} from '@mui/material';
import styled from 'styled-components';
import { Typography } from '../Typography';
import { COLORS } from 'theme/colors';
import { hexToRGB } from 'utils/common';
import { StyledSliderProps } from './RangeSlider.d';

export const StyledSlider = MUIstyled(Slider)<StyledSliderProps>(
  ({ isFirstFocus }) => ({
    color: isFirstFocus ? COLORS.info.selectedBorder : COLORS.accent.disabled,
    fontFamily: '"Inter", sans-serif',
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: isFirstFocus
        ? COLORS.info.selectedBorder
        : COLORS.background.secondary,
      border: isFirstFocus
        ? `2px solid ${COLORS.background.secondary}`
        : 'none',
      outline: `1px solid ${
        isFirstFocus ? COLORS.info.selectedBorder : COLORS.stroke.primary
      }`,
      boxShadow: 'none',
      '&:hover': {
        boxShadow: `0 0 0 8px ${hexToRGB(COLORS.info.selectedBorder, '0.16')}`,
      },
      '&::before': {
        display: 'none',
      },
    },
    '& .MuiSlider-track': {
      height: 6,
      border: 0,
    },
    '& .MuiSlider-rail': {
      color: COLORS.background.neutral,
      opacity: 1,
      height: 6,
    },
  }),
);

export const StyledTextField = MUIstyled(TextField)<StyledSliderProps>(
  ({ isFirstFocus }) => ({
    height: 49,
    fontSize: 14,
    fontFamily: '"Inter", sans-serif',
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: COLORS.stroke.primary,
    },
    '& .Mui-focused': {
      [`& .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: `${COLORS.stroke.border} !important`,
        borderWidth: `4px !important`,
        top: -2,
      },
    },
    [`& .${outlinedInputClasses.input}`]: {
      padding: '16.5px 2px',
      color: isFirstFocus
        ? COLORS.typography.main
        : COLORS.typography.placeholder,
    },
    [`& .${outlinedInputClasses.root}`]: {
      padding: '7.5px 4px 7.5px 14px',
      minWidth: 64,
      maxWidth: 64,
      flexWrap: 'nowrap',
      fontSize: 14,
      boxSizing: 'border-box',
      borderWidth: '1px !important',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&::placeholder': {
        color: COLORS.typography.placeholder,
      },
      '&:hover': {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: COLORS.typography.caption,
        },
      },
    },
  }),
);

export const StyledInputLabel = MUIstyled(InputLabel)({
  fontFamily: '"Inter", sans-serif',
  fontSize: 12,
  lineHeight: '14px',
  color: COLORS.typography.placeholder,
  marginBottom: 8,
});

export const StyledLabel = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 28px;
`;
