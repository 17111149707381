import { useState, FC, useEffect } from 'react';
import { TabsType } from './Tabs.d';
import { TabItem, TabsList } from './Tabs.styled';
import { slugify } from 'utils/common';
import { useSearchParams } from 'react-router-dom';

export const Tabs: FC<TabsType> = ({ tabs, onTabChange }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab') || '';
  const [activeTab, setActiveTab] = useState(slugify(tab || tabs[0].label));

  const handleClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    newActiveTab: string,
  ) => {
    e.preventDefault();
    setActiveTab(slugify(newActiveTab));
    onTabChange && onTabChange(newActiveTab);
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('tab', newActiveTab);
    newSearchParams.delete('peer_group');
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    if (tab.length !== 0) {
      setActiveTab(slugify(tab));
    }
  }, [tab]);

  return (
    <>
      <TabsList>
        {tabs.map((tab) => {
          const { label, count } = tab;
          return (
            <TabItem
              key={label}
              onClick={(e) => handleClick(e, label)}
              active={slugify(label) === activeTab}
            >
              {label}
              {tab.count && <span> ({count})</span>}
            </TabItem>
          );
        })}
      </TabsList>
      {tabs.map((tab) => {
        if (slugify(tab.label) === activeTab)
          return <div key={tab.label}>{tab.content}</div>;
      })}
    </>
  );
};
