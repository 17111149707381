import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  width: 100%;

  ${SIZES.media.tablet} {
    width: ${SIZES.modal.inviteUser};
  }
`;

export const StyledTitle = styled(Typography)`
  font-size: 24px;
`;

export const StyledDescription = styled(Typography)`
  font-size: 12px;
  color: ${COLORS.typography.placeholder};
  line-height: 15px;
`;

export const StyledErrorText = styled.p`
  color: ${COLORS.error.main};
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-top: 16px;
`;
