import { FC } from 'react';
import { SFBBoxWithTrendProps } from './SFBBoxWithTrend.d';
import { BoxWithBookmark } from '../BoxWithBookmark';
import { Box } from 'components/common/Box';
import {
  StyledContainer,
  StyledHeaderItem,
  StyledRowContainer,
  StyledInfoIcon,
  StyledItemContainer,
} from './SFBBoxWithTrend.styled';
import { options } from './config';
import { CustomTooltip } from 'components/common/Tooltip';
import { COLORS } from 'theme/colors';
import { Trend } from '../Trend';
import { TableChip } from 'components/common/TableChip';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { getFilterByValue } from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/utils';

export const SFBBoxWithTrend: FC<SFBBoxWithTrendProps> = ({
  data,
  trend,
  trendTitle,
  trendTooltip,
  headTooltip,
  periods,
}) => {
  return (
    <BoxWithBookmark tooltip={headTooltip}>
      <StyledContainer px={3} py={2} display="grid" gridAutoFlow="column">
        <Box>
          <StyledRowContainer
            py={1.5}
            display="flex"
            justifyContent="space-between"
            gap={1}
          >
            <StyledHeaderItem
              variant="body"
              weight="semibold"
              color={COLORS.typography.description}
            >
              Zeiträume
            </StyledHeaderItem>
            <Box display="flex" justifyContent="flex-end" gap={3}>
              {periods.map((header) => (
                <StyledItemContainer
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{ width: 118 }}
                >
                  <StyledHeaderItem
                    variant="body"
                    weight="semibold"
                    color={COLORS.typography.description}
                  >
                    {header.columnName}
                  </StyledHeaderItem>
                  {header.tooltipInfo && (
                    <CustomTooltip title={header.tooltipInfo} placement="top">
                      <StyledInfoIcon stroke={COLORS.typography.placeholder} />
                    </CustomTooltip>
                  )}
                </StyledItemContainer>
              ))}
            </Box>
          </StyledRowContainer>
          {data.length !== 0 &&
            data.map((item) => (
              <StyledRowContainer
                py={1.5}
                display="flex"
                justifyContent="space-between"
                gap={1}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ minWidth: 147 }}
                >
                  <StyledHeaderItem variant="body" weight="semibold">
                    {item.categoryName}
                  </StyledHeaderItem>
                  {item.tooltipInfo && (
                    <CustomTooltip title={item.tooltipInfo} placement="top">
                      <StyledInfoIcon stroke={COLORS.typography.placeholder} />
                    </CustomTooltip>
                  )}
                </Box>
                <Box display="flex" justifyContent="center" gap={3}>
                  {Object.values(item.values).map((value) => (
                    <StyledItemContainer>
                      {value ? (
                        <TableChip
                          size="small"
                          label={
                            value.toString().toLocaleLowerCase() as ChipVariant
                          }
                          tableChipVaraint={
                            getFilterByValue(value.toString(), options)
                              ?.chipVariant
                          }
                        />
                      ) : (
                        '-'
                      )}
                    </StyledItemContainer>
                  ))}
                </Box>
              </StyledRowContainer>
            ))}
        </Box>
        <Trend
          trend={trend}
          trendTitle={trendTitle}
          trendTooltip={trendTooltip}
        />
      </StyledContainer>
    </BoxWithBookmark>
  );
};
