import { Header } from 'components/Modules/Header';
import { Layout } from 'components/common/Layout';
import { FC, useEffect } from 'react';
import { ReactComponent as HeaderIcon } from 'assets/Icons/fc-smart-fund-benchmarking.svg';
import { Box } from 'components/common/Box';
import { FCSmartFundBenchmarkingTable } from 'components/Modules/Tables/FCSmartFundBenchmarkingTable';
import { Tabs } from 'components/common/Tabs';

export const FCSmartFundBenchmarking: FC = () => {
  const tabs = [
    {
      label: 'Fonds',
      content: <FCSmartFundBenchmarkingTable variant="standart" />,
    },
    {
      label: 'Peergroups',
      content: <FCSmartFundBenchmarkingTable variant="standart" />,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout headerVariant="secondary">
      <Box px={2.5} pt={2.3} pb={5}>
        <Header
          title="FondsConsult Smart Fund Benchmarking"
          descriptions={[
            'Erleben Sie Fondsanalyse neu mit unserem innovativen Smart Fund Benchmarking. Wir bewerten alle in Deutschland zum Vertrieb zugelassenen Fonds innerhalb ihrer Vergleichsgruppe nicht nur anhand quantitativer, sondern auch qualitativer Daten. Mit Hilfe unseres Ansatzes erhalten Sie einen schnellen Überblick über die Eigenschaften und Alleinstellungsmerkmale verschiedener Produkte und können mit Hilfe unserer umfangreichen Filterfunktionen den perfekt zu Ihren Anforderungen passenden Fonds finden.',
          ]}
          icon={<HeaderIcon />}
        />
      </Box>
      <Tabs tabs={tabs} />
    </Layout>
  );
};
