import { FC } from 'react';
import {
  StyledHead,
  StyledInfoIcon,
  StyledContainer,
} from './BoxWithBookmark.styled';
import { CustomTooltip } from 'components/common/Tooltip';
import { COLORS } from 'theme/colors';
import { Box } from 'components/common/Box';
import { BoxWithBookmarkProps } from './BoxWithBookmark.d';
import { defaultTitle, defaultTooltip } from './config';

export const BoxWithBookmark: FC<BoxWithBookmarkProps> = ({
  children,
  title = defaultTitle,
  tooltip = defaultTooltip,
  variant = 'primary',
}) => {
  const isPrimaryVariant = variant === 'primary';
  return (
    <Box>
      <StyledHead isPrimaryVariant={isPrimaryVariant}>
        {title}
        <CustomTooltip title={tooltip || 'Info'} placement="top">
          <StyledInfoIcon
            stroke={
              isPrimaryVariant ? COLORS.stroke.main : COLORS.accent.primary
            }
          />
        </CustomTooltip>
      </StyledHead>
      <StyledContainer isPrimaryVariant={isPrimaryVariant}>
        {children}
      </StyledContainer>
    </Box>
  );
};
