import { FC, useEffect, useState } from 'react';
import { RangeFiltersProps } from './RangeFilters.d';
import { RangeSlider } from 'components/common/RangeSlider';
import { Box } from 'components/common/Box';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const RangeFilters: FC<RangeFiltersProps> = ({ options, id, label }) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const [min, max] = options;
  const [value, setValue] = useState<number[] | undefined>(undefined);

  const handleRangeChange = (values: number[]) => {
    setValue(values);
  };

  useEffect(() => {
    const params = new URLSearchParams(search);

    params.delete(id);

    if (value && !!value.length) {
      params.append(id, value.join('-'));
    }

    params.delete('page');

    navigate(pathname + '?' + params.toString());
  }, [value]);

  useEffect(() => {
    let value;
    const filterValue = searchParams.get(id);
    if (filterValue) {
      value = filterValue.split('-').map(Number);
    } else {
      value = undefined;
    }
    setValue(value);
  }, [searchParams]);

  return (
    <Box style={{ width: '398px' }}>
      <RangeSlider
        label={label || ''}
        min={Number(min.value)}
        max={Number(max.value)}
        step={5}
        currentValues={value}
        onChange={(values) => handleRangeChange(values)}
      />
    </Box>
  );
};
