import { FilterProps } from 'components/Modules/Filters/SelectedFilters/SelectedFilters.d';
import { Option } from 'components/common/Select/Select.d';

export const esgFilters: FilterProps[] = [
  { label: 'Credibility Score', id: 'credibility_score' },
  { label: 'Action Score', id: 'action_score' },
  { label: 'Anteil Artikel 8 gem. SFDR', id: 'article_8', placeholder: '%' },
  { label: 'Anteil Artikel 9 gem. SFDR', id: 'article_9', placeholder: '%' },
];

export const rangeOptions: Option[] = [
  { value: '0', name: 'Min' },
  { value: '100', name: 'Max' },
];
