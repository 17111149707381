import { Chip } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'theme/colors';
import { ChipVariant, StyledChipProps } from './TableChip.d';
import { hexToRGB } from 'utils/common';
import { TableChipVariant } from './TableChip.enums';

const getTableChipColor = (chipVariant: TableChipVariant) => {
  switch (chipVariant) {
    case TableChipVariant.VIBRANT_GREEN:
      return COLORS.background.secondary;
    case TableChipVariant.GREEN:
      return COLORS.success.dark;
    case TableChipVariant.GREY:
      return COLORS.typography.description;
    case TableChipVariant.RED:
      return COLORS.error.dark;
    case TableChipVariant.VIBRANT_RED:
      return COLORS.background.secondary;
    default:
      return COLORS.typography.description;
  }
};
const getTableChipBackground = (chipVariant: TableChipVariant) => {
  switch (chipVariant) {
    case TableChipVariant.VIBRANT_GREEN:
      return COLORS.success.bulletPoint;
    case TableChipVariant.GREEN:
      return hexToRGB(COLORS.success.bulletPoint, '0.15');
    case TableChipVariant.GREY:
      return COLORS.background.module;
    case TableChipVariant.RED:
      return hexToRGB(COLORS.error.background, '0.2');
    case TableChipVariant.VIBRANT_RED:
      return COLORS.error.background;
    default:
      return COLORS.background.module;
  }
};
const getTableChipBorderColor = (chipVariant: TableChipVariant) => {
  switch (chipVariant) {
    case TableChipVariant.VIBRANT_GREEN:
      return COLORS.success.bulletPoint;
    case TableChipVariant.GREEN:
      return hexToRGB(COLORS.success.dark, '0.15');
    case TableChipVariant.GREY:
      return COLORS.stroke.primary;
    case TableChipVariant.RED:
      return hexToRGB(COLORS.error.background, '0.2');
    case TableChipVariant.VIBRANT_RED:
      return COLORS.error.background;
    default:
      return COLORS.stroke.primary;
  }
};

const getChipPadding = (
  label: ChipVariant,
  size?: 'small' | 'medium',
): string => {
  if (size === 'small')
    return label === 'durchschnittlich' ? '12.5px 0px' : '12.5px 4px';
  switch (label) {
    case 'sehr schlecht':
    case 'durchschnittlich':
      return '23px 15px';
    default:
      return '15px 14px';
  }
};

export const StyledChip = styled(Chip)<StyledChipProps>(
  ({ theme, label, size, tableChipVaraint }) => ({
    color: getTableChipColor(tableChipVaraint),
    // color: getChipColor(label),
    background: getTableChipBackground(tableChipVaraint),
    // background: getChipBackground(label),
    height: size === 'small' ? '10px' : '14px',
    fontSize: '13px',
    fontFamily: '"Inter", sans-serif',
    padding: getChipPadding(label, size),
    [theme.breakpoints.up('xl')]: {
      padding: '15px 14px',
    },
    overflow: 'hidden',
    borderRadius: 32,
    transitionDuration: `0.2s`,
    border: `1px solid ${getTableChipBorderColor(tableChipVaraint)}`,
    // border: `1px solid ${getChipBorderColor(label)}`,
    textTransform: 'capitalize',
    minWidth: 96,

    '.MuiChip-label': {
      padding: '2px 6px !important',
      fontFamily: '"Inter", sans-serif',
      fontWeight: size === 'small' ? 500 : 600,
      whiteSpace: 'normal',
      wordBreak: label === 'durchschnittlich' ? 'break-all' : 'unset',
    },
  }),
);
