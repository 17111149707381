import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactUsSchema } from './validators';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ContactFormCategory,
  ContactFormCompletedAction,
} from 'utils/google-analytics/events/contactForm';
import { StyledForm, StyledLink } from './RegistrationContactUsForm.styled';
import { Input } from 'components/common/Input';
import { isValidForm } from 'utils/isValidForm';
import { Textarea } from 'components/common/Textarea';
import { FormCheckbox } from 'components/common/FormCheckbox';
import { Box } from 'components/common/Box';
import { Button } from 'components/common/Buttons/Button';
import {
  ContactFormProps,
  RegistrationContactUsFormProps,
} from './RegistrationContactUsForm.d';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import de from 'react-phone-number-input/locale/de.json';
import { PhoneInput } from 'components/common/PhoneInput';
import { registerContactUs } from 'services/ContactUs';
import { AppPaths } from 'urls/frontend';

const defaultCountryCallingCode = {
  value: '+49',
  name: 'Deutschland +49',
  country: 'DE',
};

export const RegistrationContactUsForm: FC<RegistrationContactUsFormProps> = ({
  closeForm,
}) => {
  const [countryCallingCode, setCountryCallingCode] = useState(
    defaultCountryCallingCode,
  );
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ContactFormProps>({
    resolver: yupResolver(contactUsSchema),
    mode: 'onBlur',
    defaultValues: {
      subject: 'Registrierung',
    },
  });
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const toggleAgreedToTerms = () => {
    if (agreedToTerms) {
      setValue('agreedToTerms', false, { shouldValidate: true });
    } else {
      setValue('agreedToTerms', true, { shouldValidate: true });
    }
    setAgreedToTerms(!agreedToTerms);
  };

  const onContactFormSubmit = async (data: ContactFormProps) => {
    const { agreedToTerms, phone_number_code, ...formData } = data;
    const response = await registerContactUs({
      ...formData,
      phone_number: `${phone_number_code}${data.phone_number}`,
    });

    if (response.ok) {
      raiseToast.success('Formular erfolgreich gesendet.');
      reset();
      setAgreedToTerms(false);
      closeForm();
      TrackGoogleAnalyticsEvent(
        ContactFormCategory,
        ContactFormCompletedAction,
        window.location.pathname,
      );
    }
  };

  const handleCountryCallingCodeChange = (newCountry: string) => {
    const [newSelectedCountry] = getCountries().filter(
      (country) => country === newCountry,
    );
    if (newSelectedCountry) {
      setCountryCallingCode({
        value: `+${getCountryCallingCode(newSelectedCountry)}`,
        name: `${de[newSelectedCountry]} +${getCountryCallingCode(
          newSelectedCountry,
        )}`,
        country: newSelectedCountry,
      });
    }
  };

  const getCountryCodesOptions = () => {
    return getCountries().map((country) => ({
      value: `+${getCountryCallingCode(country)}`,
      name: `${de[country]} +${getCountryCallingCode(country)}`,
      country,
    }));
  };

  useEffect(() => {
    setValue('phone_number_code', countryCallingCode.value);
  }, [countryCallingCode]);

  return (
    <StyledForm onSubmit={handleSubmit(onContactFormSubmit)}>
      <Input
        placeholder="Ihr Name"
        {...register('sender_name', {
          required: true,
        })}
        error={!isValidForm(errors) && errors.sender_name?.message}
      />
      <Input
        placeholder="E-Mail-Adresse"
        {...register('sender_email', {
          required: true,
        })}
        error={!isValidForm(errors) && errors.sender_email?.message}
      />
      <PhoneInput
        {...register('phone_number', {
          required: true,
        })}
        placeholder="Telefonnummer"
        codeValue={countryCallingCode}
        setCodeValue={handleCountryCallingCodeChange}
        options={getCountryCodesOptions()}
        error={!isValidForm(errors) && errors.phone_number?.message}
        codeError={!isValidForm(errors) && errors.phone_number_code?.message}
      />
      <Input
        placeholder="Thema"
        {...register('subject', {
          required: true,
        })}
        disabled
        error={!isValidForm(errors) && errors.subject?.message}
      />
      <Input
        placeholder="Unternehmen"
        {...register('company', {
          required: true,
        })}
        error={!isValidForm(errors) && errors.company?.message}
      />
      <Input
        placeholder="Position"
        {...register('position', {
          required: true,
        })}
        error={!isValidForm(errors) && errors.position?.message}
      />
      <Textarea
        placeholder="Nachricht"
        {...register('body', {
          required: true,
        })}
        error={!isValidForm(errors) && errors.body?.message}
      />
      <FormCheckbox
        {...register('agreedToTerms', {
          required: true,
        })}
        options={[
          {
            value:
              'Durch die Nutzung dieses Kontaktformulars akzeptieren Sie unsere Allgemeinen Geschäftsbedingungen',
            label: (
              <>
                Durch Klicken stimmen Sie unseren{' '}
                <StyledLink to={AppPaths.termsAndConditions}>
                  Nutzungsbedingungen
                </StyledLink>{' '}
                sowie der{' '}
                <StyledLink to={AppPaths.privacyPolicy}>
                  Datenschutzerklärung nach DSGVO
                </StyledLink>
                .
              </>
            ),
            checked: agreedToTerms,
          },
        ]}
        onSelect={toggleAgreedToTerms}
        error={!isValidForm(errors) && errors.agreedToTerms?.message}
      />
      <Box mt={2}>
        <Button type="submit">Nachricht senden</Button>
      </Box>
    </StyledForm>
  );
};
