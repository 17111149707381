import { Option } from 'components/common/Select/Select.d';
import { TableChipVariant } from 'components/common/TableChip/TableChip.enums';

export const options: Option[] = [
  { value: 'Hoch', name: 'Hoch', chipVariant: TableChipVariant.GREEN },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Niedrig', name: 'Niedrig', chipVariant: TableChipVariant.RED },
];

export const assetConcentrationOptions: Option[] = [
  { value: 'Hoch', name: 'Hoch', chipVariant: TableChipVariant.RED },
  { value: 'Mittel', name: 'Mittel', chipVariant: TableChipVariant.GREY },
  { value: 'Niedrig', name: 'Niedrig', chipVariant: TableChipVariant.GREEN },
];
