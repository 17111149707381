import { FC } from 'react';
import { FundManagementBondProps } from './FundManagementBond.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { BoxWithBookmark } from '../BoxWithBookmark';
import { StyledTypography } from './FundManagementBond.styled';
import { TableChip } from 'components/common/TableChip';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { COLORS } from 'theme/colors';
import { BasicTable } from '../BasicTable';
import { getFilterByValue } from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/utils';
import { options } from './config';
import { managementAffiliationOptions } from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/config';

export const FundManagementBond: FC<FundManagementBondProps> = ({
  description,
  averageCreditRating,
  yieldToMaturity,
  averageRemainingTerm,
  modifiedDuration,
  managementAffiliation,
  trackingError,
}) => {
  const data = [
    {
      label: 'Durchschnittliches Kredit-Rating (YTM)',
      value: averageCreditRating ? (
        <TableChip
          label={averageCreditRating.toLowerCase() as ChipVariant}
          size="small"
          tableChipVaraint={
            getFilterByValue(averageCreditRating, options)?.chipVariant
          }
        />
      ) : (
        <span>-</span>
      ),
    },
    {
      label: 'Rendite auf Verfall (YTM)',
      value: yieldToMaturity ? (
        <TableChip
          label={yieldToMaturity.toLowerCase() as ChipVariant}
          size="small"
          tableChipVaraint={
            getFilterByValue(yieldToMaturity, options)?.chipVariant
          }
        />
      ) : (
        <span>-</span>
      ),
    },
    {
      label: 'Durchschnittliche Restlaufzeit',
      value: averageRemainingTerm ? (
        <TableChip
          label={averageRemainingTerm.toLowerCase() as ChipVariant}
          size="small"
          tableChipVaraint={
            getFilterByValue(averageRemainingTerm, options)?.chipVariant
          }
        />
      ) : (
        <span>-</span>
      ),
    },
    {
      label: 'Modified Duration',
      value: modifiedDuration ? (
        <TableChip
          label={modifiedDuration.toLowerCase() as ChipVariant}
          size="small"
          tableChipVaraint={
            getFilterByValue(modifiedDuration, options)?.chipVariant
          }
        />
      ) : (
        <span>-</span>
      ),
    },
    {
      label: 'Managementzugeörigkeit',
      value: managementAffiliation ? (
        <TableChip
          label={managementAffiliation.toLowerCase() as ChipVariant}
          size="small"
          tableChipVaraint={
            getFilterByValue(
              managementAffiliation,
              managementAffiliationOptions,
            )?.chipVariant
          }
        />
      ) : (
        <span>-</span>
      ),
    },
    {
      label: 'Tracking error',
      value: trackingError ? (
        <TableChip
          label={trackingError.toLowerCase() as ChipVariant}
          size="small"
          tableChipVaraint={
            getFilterByValue(trackingError, options)?.chipVariant
          }
        />
      ) : (
        <span>-</span>
      ),
    },
  ];

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      {data.length !== 0 && (
        <BoxWithBookmark>
          <BasicTable
            pt={1.5}
            px={2}
            pb={3}
            columnNumber={2}
            rowNumber={3}
            data={data.map((item) => ({
              label: (
                <StyledTypography variant="body" weight="semibold">
                  {item.label}
                </StyledTypography>
              ),
              value: item.value,
            }))}
          />
        </BoxWithBookmark>
      )}
    </Box>
  );
};
