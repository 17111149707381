import { useState, useContext, createContext, ReactNode, FC } from 'react';
import { UserProfile, UserProfileResponse } from 'types/User.d';
import { fetchProfile } from 'services/Profile';
import { permissions } from './config';

export type Profile = {
  profileInfo: UserProfile | null;
  fetchProfileData: () => void;
};

const profileContext = createContext<Profile | null>(null);

export const useProvideProfile = (): Profile => {
  const [profileInfo, setProfileInfo] = useState<UserProfile | null>(null);

  const hasUserPermissions = (
    userPermissions: string[],
    permission: string,
  ) => {
    return userPermissions.includes(permission);
  };

  const fetchProfileData = async () => {
    const response = await fetchProfile();
    const profileData: UserProfileResponse = response.parsedBody;
    const userPermissions = profileData.user_permissions;
    if (profileData) {
      setProfileInfo({
        email: profileData.email,
        mlp_depots_access: hasUserPermissions(
          userPermissions,
          permissions.user.mlp_depots_access,
        ),
        can_invite_users: hasUserPermissions(
          userPermissions,
          permissions.user.invite_users,
        ),
      });
    }
  };

  return { profileInfo, fetchProfileData };
};

interface IProvideProfile {
  children: ReactNode;
}

export const ProfileProvider: FC<IProvideProfile> = ({ children }) => {
  const profile = useProvideProfile();
  return (
    <profileContext.Provider value={profile}>
      {children}
    </profileContext.Provider>
  );
};

export const useProfile = () => {
  return useContext(profileContext);
};
