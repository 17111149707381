import { styled as MUIstyled } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, {
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { COLORS } from 'theme/colors';
import styled from 'styled-components';
import {
  AccordionItemType,
  StyledFilterAccordionProps,
} from './FilterAccordion.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';

export const StyledCount = styled(Box)<AccordionItemType>`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${COLORS.accent.primary};
  color: ${COLORS.background.primary};

  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  font-size: 12px;
  font-weight: 400;

  transition-duration: 0.2s;
`;

export const StyledAccordion = MUIstyled(MuiAccordion)(
  ({ expanded, disabled }) => ({
    transitionDuration: '0.2s',
    borderTop: `1px solid ${COLORS.stroke.main}`,
    '&.Mui-disabled': {
      backgroundColor: COLORS.background.secondary,
    },
    '&:first-child': {
      borderTop: 0,
    },
    '&::before': {
      display: 'none',
    },
    ...(!expanded && !disabled
      ? {
          '&:hover': {
            [`& .count`]: {
              color: COLORS.background.primary,
              backgroundColor: COLORS.accent.primary,
            },
            '& .MuiAccordionSummary-expandIconWrapper': {
              '& svg': {
                fill: COLORS.accent.primary,
              },
            },
            [`.${accordionSummaryClasses.content}`]: {
              color: COLORS.accent.primary,
            },
          },
        }
      : {}),
  }),
);

export const StyledAccordionSummary = MUIstyled(
  MuiAccordionSummary,
)<StyledFilterAccordionProps>(({ isSmallVariant, isActive }) => ({
  flexDirection: 'row-reverse',
  padding: isSmallVariant ? '19.5px 0' : '23.5px 0',
  paddingBottom: isActive ? 0 : isSmallVariant ? '19.5px' : '23.5px',
  minHeight: 24,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)',
  },
  [`.${accordionSummaryClasses.content}`]: {
    margin: '0 0 0 12px',
  },
  '& .Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
}));

export const StyledAccordionDetails = MUIstyled(
  MuiAccordionDetails,
)<StyledFilterAccordionProps>(({ isSmallVariant }) => ({
  padding: isSmallVariant ? '20px 0px 32px' : '0 28px 0 32px',
  borderTop: 0,
}));

export const StyledLabel = styled(Typography)<StyledFilterAccordionProps>`
  font-size: ${({ isSmallVariant }) => (isSmallVariant ? '15px' : '18px')};
  font-weight: 500;
  line-height: 24px;
  color: unset;

  transition-duration: 0.2s;
`;

export const StyledItemContainer = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
`;
