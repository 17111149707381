import { FC, useEffect, useState } from 'react';
import { Box } from 'components/common/Box';
import { ModuleLayout } from 'components/Modules/ModuleLayout';
import { Table } from 'components/common/Table';
import {
  GridRowsProp,
  GridColDef,
  GridSortModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {
  ESGAndActionScoreItemProps,
  ESGCredibilityActionScoreTableProps,
} from './ESGCredibilityActionScoreTable.d';
import { NameColumn } from 'components/Modules/NameColumn';
import { Rank } from 'components/Modules/Rank';
import { Score } from 'components/Modules/Score';
import { Actions } from 'components/Modules/Actions';
import { Pagination } from 'components/common/Pagination';
import {
  fetchAddFundsToWatchlistEsg,
  fetchESGAndActionScore,
  fetchFavoritesESGAndActionScore,
  fetchUpdateFundListStatus,
  sendRequestReportESGFund,
} from 'services/Modules';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { esgFilters, rangeOptions } from './config';
import { SelectedRow } from 'components/common/Table/SelectedRow/SelectedRow';
import { RemoveFromFundListModal } from 'components/common/Modals/RemoveFromFundListModal';
import { AddFeedbackModal } from 'components/common/Modals/AddFeedbackModal';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { Toast } from 'components/common/Toast';
import { TableCellDetailModal } from 'components/common/Modals/TableCellDetailModal';
import { TableCellDetailProps } from 'components/common/Modals/TableCellDetailModal/TableCellDetailModal.d';
import {
  StyledArticle,
  StyledCell,
  StyledHeadCellContainer,
  StyledInfoIcon,
} from './ESGCredibilityActionScoreTable.styled';
import { Typography } from 'components/common/Typography';
import { CustomTooltip } from 'components/common/Tooltip';
import { COLORS } from 'theme/colors';
import { ESGFiltersModal } from 'components/Modules/Filters/ESGFiltersModal';
import { SelectedFilters } from 'components/Modules/Filters/SelectedFilters';

export const ESGCredibilityActionScoreTable: FC<
  ESGCredibilityActionScoreTableProps
> = ({ variant }) => {
  const isStandartVariant = variant === 'standart';
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [loading, setLoading] = useState(true);
  const [resultsNumber, setResultsNumber] = useState<number>(1);
  const [openFilters, setOpenFilters] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>(
    undefined,
  );
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [isConfirmRemoveFromFundOpen, setIsConfirmRemoveFromFundOpen] =
    useState(false);
  const [isAddingFeedbackModalOpen, setIsAddingFeedbackModalOpen] =
    useState(false);
  const [isTableCellDetailModalOpen, setIsTableCellDetailModalOpen] =
    useState(false);
  const [currentFund, setCurrentFund] = useState({
    id: '',
    name: '',
  });
  const [tableCellDetails, setTableCellDetail] = useState<
    TableCellDetailProps[]
  >([]);
  const query = new URLSearchParams(search);
  const page = parseInt(query.get('page') || '1', 10);
  const itemsPerPage = parseInt(query.get('page-size') || '10', 10);
  const credibilityScore = searchParams.get('credibility_score') || '';
  const actionScore = searchParams.get('action_score') || '';
  const article_8 = searchParams.get('article_8') || '';
  const article_9 = searchParams.get('article_9') || '';

  const toggleFiltersSection = () => setOpenFilters(!openFilters);

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
    resetPage();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const getTableData = async () => {
    const [credibility_min, credibility_max] = credibilityScore.split('-');
    const [action_min, action_max] = actionScore.split('-');
    const [article_8_min, article_8_max] = article_8.split('-');
    const [article_9_min, article_9_max] = article_9.split('-');
    const data = {
      page,
      page_size: itemsPerPage,
      ...(credibility_min ? { credibility_min } : {}),
      ...(credibility_max ? { credibility_max } : {}),
      ...(action_min ? { action_min } : {}),
      ...(action_max ? { action_max } : {}),
      ...(article_8_min ? { article_8_min } : {}),
      ...(article_8_max ? { article_8_max } : {}),
      ...(article_9_min ? { article_9_min } : {}),
      ...(article_9_max ? { article_9_max } : {}),
      ...(sortModel &&
      sortModel.length !== 0 &&
      sortModel[0].field &&
      sortModel[0].sort
        ? { sort_by: sortModel[0].field, sort_order: sortModel[0].sort }
        : {}),
      ...(searchValue ? { search: searchValue } : {}),
    };
    const response = isStandartVariant
      ? await fetchESGAndActionScore(data)
      : await fetchFavoritesESGAndActionScore(data);
    if (response.ok) {
      const tableData = response.parsedBody.results.funds;
      setRows(tableData.map((p: ESGAndActionScoreItemProps) => createRow(p)));
      setResultsNumber(response.parsedBody.count);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [
    page,
    itemsPerPage,
    searchParams,
    credibilityScore,
    actionScore,
    article_8,
    article_9,
    sortModel,
    searchValue,
  ]);

  const resetPage = () => {
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const getPagesNumber = () => {
    return Math.ceil(resultsNumber / +itemsPerPage);
  };

  const changeFundListStatus = async (id: string, name: string) => {
    const response = await fetchUpdateFundListStatus(id);
    if (response.ok) {
      getTableData();
      isConfirmRemoveFromFundOpen && setIsConfirmRemoveFromFundOpen(false);
      isConfirmRemoveFromFundOpen
        ? raiseToast.removeFromFundList(
            <Toast
              title="Fonds aus Ihrer Fondsliste entfernt"
              content={`"${name}" wurde aus Ihrer Fondsliste entfernt.`}
            />,
          )
        : raiseToast.addToFundList(
            <Toast
              title="Fonds zu Ihrer Fondsliste hinzugefügt"
              content={`"${name}" wurde zu Ihrer Fondsliste hinzugefügt.`}
            />,
          );
    }
  };

  const requestReport = async (id: string, name: string) => {
    const response = await sendRequestReportESGFund(id);
    if (response.ok) {
      raiseToast.success(
        <Toast
          title="Die Anfrage für den FondsConsult Nachhaltigkeitsbericht wurde erfolgreich gesendet"
          content={`Wir werden Ihnen eine E-Mail bezüglich des Fonds "${name}" senden`}
        />,
      );
    }
  };

  const showAddFeedbackModal = (name: string, id: string) => {
    if (name) {
      setIsAddingFeedbackModalOpen(true);
      setCurrentFund({ id, name });
    }
  };

  const showConfirmRemoveFromFundListModal = (name: string, id: string) => {
    setIsConfirmRemoveFromFundOpen(true);
    setCurrentFund({ id, name });
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 226,
      flex: 2,
      sortable: true,
      renderCell: ({ row }) => <NameColumn name={row.name} />,
    },
    {
      field: 'rank',
      headerName: 'Rang Credibility Score',
      align: 'center',
      minWidth: 100,
      maxWidth: 100,
      sortable: true,
      renderCell: ({ row }) => <Rank value={row.rank} maxValue={row.rankAll} />,
      renderHeader: () => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          px={0.5}
        >
          <CustomTooltip title="Rang des Credibility Scores." placement="top">
            <StyledInfoIcon stroke={COLORS.typography.placeholder} />
          </CustomTooltip>
          <Typography
            variant="body"
            color={COLORS.typography.description}
            weight="semibold"
          >
            Rang
          </Typography>
        </Box>
      ),
    },
    {
      field: 'credibility_score',
      headerName: 'Credibility Score',
      minWidth: 180,
      align: 'center',
      renderHeader: () => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          px={0.5}
        >
          <CustomTooltip
            title="Bewertung der Glaubwürdigkeit der Nachhaltigkeitsbemühungen einer Fondsgesellschaft. Score erstreckt sich von 0 bis 100 Punkten, wobei ab 60 Punkten von einer hohen Glaubwürdigkeit gesprochen werden kann."
            placement="top"
          >
            <StyledInfoIcon stroke={COLORS.typography.placeholder} />
          </CustomTooltip>
          <Typography
            variant="body"
            color={COLORS.typography.description}
            weight="semibold"
          >
            Credibility Score
          </Typography>
        </Box>
      ),
      renderCell: ({ row, value }) => (
        <StyledCell
          onClick={(e: React.BaseSyntheticEvent) => {
            const esgCredibilityTableCellDetails = [
              {
                categoryName: 'ESG Credibility',
                withMark: false,
                data: [
                  {
                    label: 'Gesamtscore',
                    value: value || null,
                  },
                  {
                    label:
                      'Organisationsstruktur und vertrauensbildende Maßnahmen',
                    value: row.organizational_structure?.toString() || null,
                  },
                  {
                    label: 'Unternehmenspolitik und -umsetzung',
                    value: row.corporate_policy?.toString() || null,
                  },
                  {
                    label: 'Auswahl und Überwachung (externer) Manager',
                    value: row.external_managers_monitoring?.toString() || null,
                  },
                  {
                    label: 'Aktien',
                    value: row.equities?.toString() || null,
                  },
                  {
                    label: 'Anleihen',
                    value: row.bonds?.toString() || null,
                  },
                  {
                    label: 'Immobilien',
                    value: row.real_estate?.toString() || null,
                  },
                  {
                    label: 'Infrastruktur',
                    value: row.infrastructure?.toString() || null,
                  },
                  {
                    label: 'Private Equity',
                    value: row.private_equity?.toString() || null,
                  },
                  {
                    label: 'Hedgefonds',
                    value: row.hedge_funds?.toString() || null,
                  },
                  {
                    label: 'Transparenz',
                    value: row.transparency?.toString() || null,
                  },
                ],
              },
            ];
            e.stopPropagation();
            setIsTableCellDetailModalOpen(true);
            setTableCellDetail(esgCredibilityTableCellDetails);
            setCurrentFund({
              id: row.id,
              name: row.name,
            });
          }}
        >
          <Score value={row.credibility_score} />
        </StyledCell>
      ),
    },
    {
      field: 'action_score',
      headerName: 'Action Score',
      minWidth: 155,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          px={0.5}
        >
          <CustomTooltip
            title="Bewertung der auf Fondsebene tatsächlich umgesetzten Nachhaltigkeitskriterien (u.a. PAIs). Score erstreckt sich von 0 bis 100 Punkten, wobei ab 60 Punkten von einer hohen Berücksichtigung nachhaltiger Kriterien gesprochen werden kann."
            placement="top"
          >
            <StyledInfoIcon stroke={COLORS.typography.placeholder} />
          </CustomTooltip>
          <Typography
            variant="body"
            color={COLORS.typography.description}
            weight="semibold"
          >
            Action Score
          </Typography>
        </Box>
      ),
      renderCell: ({ row }) => <Score value={row.action_score} />,
    },
    {
      field: 'article_8',
      headerName: 'Anteil Artikel 8 gemäß SFDR',
      minWidth: 180,
      align: 'center',
      renderCell: ({ value }) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ height: '100%' }}
        >
          <StyledArticle variant="body">
            {value !== undefined && value !== null ? `${value}%` : '-'}
          </StyledArticle>
        </Box>
      ),
      renderHeader: () => (
        <StyledHeadCellContainer
          display="flex"
          alignItems="center"
          justifyContent="center"
          px={0.5}
        >
          <CustomTooltip
            title="Volumengewichteter Anteil aller in Europa (ex UK) zugelassenen Fonds im UCITS-Mantel."
            placement="top"
          >
            <StyledInfoIcon stroke={COLORS.typography.placeholder} />
          </CustomTooltip>
          <Typography
            variant="body"
            color={COLORS.typography.description}
            weight="semibold"
            textAlign="center"
          >
            Anteil Artikel 8 gemäß SFDR
          </Typography>
        </StyledHeadCellContainer>
      ),
    },
    {
      field: 'article_9',
      headerName: 'Anteil Artikel 9 gemäß SFDR',
      minWidth: 180,
      align: 'center',
      renderCell: ({ value }) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ height: '100%' }}
        >
          <StyledArticle variant="body">
            {value !== undefined && value !== null ? `${value}%` : '-'}
          </StyledArticle>
        </Box>
      ),
      renderHeader: () => (
        <StyledHeadCellContainer
          display="flex"
          alignItems="center"
          justifyContent="center"
          px={0.5}
        >
          <CustomTooltip
            title="Volumengewichteter Anteil aller in Europa (ex UK) zugelassenen Fonds im UCITS-Mantel."
            placement="top"
          >
            <StyledInfoIcon stroke={COLORS.typography.placeholder} />
          </CustomTooltip>
          <Typography
            variant="body"
            color={COLORS.typography.description}
            weight="semibold"
            textAlign="center"
          >
            Anteil Artikel 9 gemäß SFDR
          </Typography>
        </StyledHeadCellContainer>
      ),
    },
    {
      field: 'assessment',
      headerName: 'Einschätzung',
      minWidth: 130,
      maxWidth: 150,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          px={0.5}
        >
          <CustomTooltip title="Bald verfügbar." placement="top">
            <StyledInfoIcon stroke={COLORS.typography.placeholder} />
          </CustomTooltip>
          <Typography
            variant="body"
            color={COLORS.typography.description}
            weight="semibold"
          >
            Einschätzung
          </Typography>
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 160,
      sortable: false,
      headerAlign: 'right',
      renderCell: ({ row }) => (
        <Actions
          isInMyFundList={row.is_favoured}
          requestReportText="FondsConsult Nachhaltigkeitsreport anfordern"
          onChangeFundListHandle={() =>
            row.is_favoured
              ? showConfirmRemoveFromFundListModal(row.name, row.id)
              : changeFundListStatus(row.id, row.name)
          }
          onRequestReportHandler={() => requestReport(row.id, row.name)}
          onFeedbackHandle={() => showAddFeedbackModal(row.name, row.id)}
        />
      ),
    },
  ];

  const createRow = (tableData: ESGAndActionScoreItemProps) => {
    return {
      id: tableData.id,
      name: tableData.name,
      rank: tableData.rank,
      rankAll: tableData.rank_all,
      credibility_score: tableData.credibility_score,
      action_score: tableData.action_score,
      article_8: tableData.article_8,
      article_9: tableData.article_9,
      actions: tableData,
      assessment: tableData.assessment || '-',
      organizational_structure: tableData.organizational_structure,
      corporate_policy: tableData.corporate_policy,
      external_managers_monitoring: tableData.external_managers_monitoring,
      equities: tableData.equities,
      bonds: tableData.bonds,
      real_estate: tableData.real_estate,
      infrastructure: tableData.infrastructure,
      private_equity: tableData.private_equity,
      hedge_funds: tableData.hedge_funds,
      transparency: tableData.transparency,
      is_favoured: tableData.is_favoured,
    };
  };

  const onSubmitSearch = (value: string) => {
    setSearchValue(value);
    resetPage();
  };

  const getActiveFiltersAmount = () =>
    [credibilityScore, actionScore, article_8, article_9].filter(
      (el) => el.length !== 0,
    ).length;

  const addMultipleFundsToMyWatchlist = async () => {
    const response = await fetchAddFundsToWatchlistEsg(
      rowSelectionModel.map((item) => item.toString()),
    );
    if (response.ok) {
      raiseToast.addToFundList(
        'Fonds, die zu Ihrer Fondsliste hinzugefügt wurden.',
      );
      setRowSelectionModel([]);
      getTableData();
    }
  };

  const isAddMultipleFundsButtonEnabled = () => {
    return rows
      .filter((item) => rowSelectionModel.includes(item.id))
      .some((row) => !row.is_favoured);
  };

  const clearFilters = () => {
    const searchParams = new URLSearchParams(search);
    const tabSearchParam = searchParams.get('tab');
    const newSearchParams = new URLSearchParams();

    if (tabSearchParam) {
      newSearchParams.set('tab', tabSearchParam);
    }

    navigate(`${pathname}${tabSearchParam ? `?${newSearchParams}` : ''}`);
  };

  const getFilters = () =>
    esgFilters.map((filter) => {
      let value;
      const filterValue = searchParams.get(filter.id);
      if (filterValue && filter.placeholder) {
        const [firstValue, secondValue] = filterValue.split('-');
        value = `${firstValue}${filter.placeholder} - ${secondValue}${filter.placeholder}`;
      } else {
        value = filterValue;
      }
      return {
        ...filter,
        name: value || '',
        value: filterValue || '',
        category: 'ESG',
      };
    });

  return (
    <>
      <RemoveFromFundListModal
        isShown={isConfirmRemoveFromFundOpen}
        toggle={() => setIsConfirmRemoveFromFundOpen(false)}
        onSubmit={() => changeFundListStatus(currentFund.id, currentFund.name)}
      />
      <AddFeedbackModal
        variant="esg"
        isShown={isAddingFeedbackModalOpen}
        currentFund={currentFund}
        toggle={() => setIsAddingFeedbackModalOpen(false)}
      />
      <TableCellDetailModal
        isShown={isTableCellDetailModalOpen}
        currentFund={currentFund}
        toggle={() => setIsTableCellDetailModalOpen(false)}
        details={tableCellDetails}
      />
      <ESGFiltersModal
        isShow={openFilters}
        closeModal={() => setOpenFilters(false)}
        clearFilters={clearFilters}
        filters={esgFilters.map((filter) => {
          return {
            ...filter,
            options: rangeOptions,
          };
        })}
      />
      <ModuleLayout
        searchValue={searchValue}
        searchPlaceholder="Fondssuche über ISIN oder Name..."
        activeFiltersAmount={getActiveFiltersAmount()}
        onSubmitSearch={onSubmitSearch}
        onToggleFilters={toggleFiltersSection}
      >
        <SelectedFilters filters={getFilters()} />
        <Box px={2.2} display="flex" flexDirection="column" gap={2}>
          <Box
            display="flex"
            flexDirection="column"
            style={{ height: loading ? 'fit-content' : 'unset' }}
          >
            {rowSelectionModel.length !== 0 && (
              <SelectedRow
                variant={variant}
                selectedItemsNumber={rowSelectionModel.length}
                isAddMultipleFundsButtonEnabled={isAddMultipleFundsButtonEnabled()}
                handleAddToWatchlist={addMultipleFundsToMyWatchlist}
                handleCancelSelectingRows={() => setRowSelectionModel([])}
              />
            )}
            <Table
              rows={rows}
              columns={columns}
              loading={loading}
              sortingMode="server"
              paginationMode="server"
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              keepNonExistentRowsSelected
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
            />
          </Box>
          <Pagination pagesNumber={getPagesNumber()} />
        </Box>
      </ModuleLayout>
    </>
  );
};
