import { FC, useEffect, useState } from 'react';
import {
  FCSmartFundBenchmarkingTableProps,
  PeerGroup,
  SFBFiltersProps,
} from './FCSmartFundBenchmarkingTable.d';
import { ModuleLayout } from 'components/Modules/ModuleLayout';
import { Box } from 'components/common/Box';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  PEER_GROUP,
  activeManagementOptions,
  returnOptions,
  riskOptions,
  sfbFilters,
  peerGroupFilters,
  trackRecordOptions,
  volumeOptions,
} from './config';
import { Option } from 'components/common/Select/Select.d';
import { AddFeedbackModal } from 'components/common/Modals/AddFeedbackModal';
import { RemoveFromFundListModal } from 'components/common/Modals/RemoveFromFundListModal';
import { NameColumn } from 'components/Modules/NameColumn';
import { TableChip } from 'components/common/TableChip';
import { Score } from 'components/Modules/Score';
import { Actions } from 'components/Modules/Actions';
import { raiseToast } from 'components/common/Toast/raiseToast';
import { Toast } from 'components/common/Toast';
import {
  fetchAddFundsToWatchlistSmartBenchmarking,
  fetchFavoritesSmartBenchmarking,
  fetchSmartBenchmarking,
  fetchUpdateFundListStatusSmartBenchmarking,
  sendRequestReportSmartBenchmarking,
  sendRequestVoteSmartBenchmarking,
} from 'services/Modules';
import {
  CreatedRowSmartFundBenchmarking,
  SmartFundBenchmarkingColumn,
  SortModelProps,
} from 'components/common/CollapseTable/CollapseTable.d';
import { SmartFundBenchmarkingItemProps } from 'types/Modules.d';
import { Rank } from 'components/Modules/Rank';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { Vote } from 'components/Modules/Vote';
import { removeLastUnderscorePart, slugify, toTitleCase } from 'utils/common';
import { Accordion } from 'components/common/Accordion';
import { SFBTable } from './SFBTable';
import {
  SmartBenchmarkingRequest,
  fetchRecommendationsForAction,
  fetchSmartBenchmarkingPeerGroup,
  fetchSmartBenchmarkingPeerGroups,
} from 'services/SmartBenchmarking';
import { COLORS } from 'theme/colors';
import { StyledTableChipContainer } from './FCSmartFundBenchmarkingTable.styled';
import { fetchUpdateComparisonListStatusSmartBenchmarking } from 'services/ComparisonList';
import { RemoveFromComparisonListModal } from 'components/common/Modals/RemoveFromComparisonListModal';
import { SFBFiltersModal } from 'components/Modules/Filters/SFBFiltersModal/SFBFiltersModal';
import { SelectedFilters } from 'components/Modules/Filters/SelectedFilters';
import { FilterProps } from 'components/Modules/Filters/SelectedFilters/SelectedFilters.d';
import { CategoryType } from './FCSmartFundBenchmarkingTable.enums';

export const FCSmartFundBenchmarkingTable: FC<
  FCSmartFundBenchmarkingTableProps
> = ({ variant }) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const isStandartVariant = variant === 'standart';
  const isRecommendationsVariant = variant === 'recommendations-for-action';
  const [searchValue, setSearchValue] = useState('');
  const [peerGroupSearchValue, setPeerGroupSearchValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [openFilters, setOpenFilters] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [rows, setRows] = useState<CreatedRowSmartFundBenchmarking[]>([]);
  const [loading, setLoading] = useState(true);
  const [resultsNumber, setResultsNumber] = useState<number>(1);
  const [isConfirmRemoveFromFundOpen, setIsConfirmRemoveFromFundOpen] =
    useState(false);
  const [
    isConfirmRemoveFromComparisonOpen,
    setIsConfirmRemoveFromComparisonOpen,
  ] = useState(false);
  const [isAddingFeedbackModalOpen, setIsAddingFeedbackModalOpen] =
    useState(false);
  const [currentFund, setCurrentFund] = useState({
    id: '',
    name: '',
    description: '',
  });
  const [peerGroupTabs, setPeerGroupTabs] = useState<PeerGroup[]>([]);

  const itemsPerPage = parseInt(searchParams.get('page-size') || '10', 10);
  const asset_class = searchParams.get('asset_class') || '';
  const tab = searchParams.get('tab') || '';
  const isPeerGroupTab = slugify(tab) === PEER_GROUP;
  const peerGroup = searchParams.get('peer_group') || '';
  const [sortModel, setSortModel] = useState<SortModelProps | undefined>(
    undefined,
  );
  const [prevFundId, setPrevFundId] = useState<string | null>(null);
  const [prevCollapseTableTab, setPrevCollapseTableTab] = useState<
    string | null
  >(null);
  const [prevCrashDrawdownsTableTab, setPrevCrashDrawdownsTableTab] = useState<
    string | null
  >(null);
  const thElements = document.querySelectorAll<HTMLTableHeaderCellElement>(
    '.collapse-table-head th',
  );

  const handleSortModelChange = (newSortModel: SortModelProps | undefined) => {
    setSortModel(newSortModel);
  };

  const getDefaultFilterValue = (value: string, options: Option[]) => {
    const [result] = options.filter((option) => option.value === value);
    return result;
  };

  const clearFilters = () => {
    const searchParams = new URLSearchParams(search);
    const tabSearchParam = searchParams.get('tab');
    const newSearchParams = new URLSearchParams();

    if (tabSearchParam) {
      newSearchParams.set('tab', tabSearchParam);
    }

    navigate(`${pathname}${tabSearchParam ? `?${newSearchParams}` : ''}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (tab && tab === 'Fonds') {
      searchParams.delete('peer_group');
      setSearchParams(searchParams);
    }
  }, [tab]);

  const resetPage = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('page');
    setSearchParams(newSearchParams);
  };

  const onSubmitSearch = (value: string) => {
    isPeerGroupTab ? setPeerGroupSearchValue(value) : setSearchValue(value);
    resetPage();
  };

  const toggleFiltersSection = () => setOpenFilters(!openFilters);

  const createRow = (
    tableData: SmartFundBenchmarkingItemProps,
  ): CreatedRowSmartFundBenchmarking => {
    return {
      id: tableData.id,
      name: tableData.name,
      category: tableData.peer_group.name || '-',
      vote: tableData.recommendations_for_action
        ? tableData.recommendations_for_action?.vote
        : null,
      rank: tableData.rank ? tableData.rank.rank : null,
      nr_of_funds: tableData.rank ? tableData.rank.nr_of_funds : null,
      volume: tableData.volume,
      track_record: tableData.track_record,
      risk: tableData.total_risk_score,
      credibility_score:
        tableData.esg_scores && tableData.esg_scores?.credibility_score
          ? tableData.esg_scores?.credibility_score?.toString()
          : null,
      return: tableData.return_score.total_score,
      total_active_management: tableData.total_active_management_score,
      is_favoured: tableData.is_favoured,
      is_compared: tableData.is_compared,
      actions: '',
      leftMark: tableData.asset_class === 'ETFs' ? 'ETF' : null,
    };
  };

  const getTableHeadWidths = () => {
    const widths: number[] = [];

    thElements.forEach((th) => {
      const width = th.getBoundingClientRect().width;
      widths.push(width);
    });
    return widths;
  };

  const getTableData = async () => {
    let response;
    setLoading(true);
    const data: SmartBenchmarkingRequest = {
      page: parseInt(searchParams.get('page') || '1', 10),
      page_size: itemsPerPage,
      ...(peerGroup ? { id: peerGroup } : {}),
      ...(sortModel && sortModel.sort_by && sortModel.sort_order
        ? { sort_by: sortModel.sort_by, sort_order: sortModel.sort_order }
        : {}),
      ...(searchValue ? { search: searchValue } : {}),
    };
    for (const filterCategory of sfbFilters) {
      for (const filter of filterCategory.data) {
        if (!!filter.id) {
          const filterValues = searchParams.getAll(filter.id.toString());
          if (!!filterValues.length) {
            if (filter.type === 'range') {
              const [min, max] = filterValues[0].split('-');
              data[`${String(filter.id)}_lte`] = min;
              data[`${String(filter.id)}_gte`] = max;
            } else if (filter.type === 'chips-article') {
              data[filter.id] = filterValues.map((item) => `Artikel ${item}`);
            } else if (
              filterCategory.categoryType === CategoryType.EQUITY_ASSET_CLASS ||
              filterCategory.categoryType === CategoryType.BOND_ASSET_CLASS ||
              filterCategory.categoryType === CategoryType.MIXED_ASSET_CLASS
            ) {
              data[removeLastUnderscorePart(filter.id)] = filterValues;
            } else {
              data[filter.id] = filterValues;
            }
          }
        }
      }
    }
    if (isStandartVariant || isRecommendationsVariant) {
      if (peerGroup.length !== 0) {
        response = await fetchSmartBenchmarkingPeerGroup(data);
      } else {
        response = isRecommendationsVariant
          ? await fetchRecommendationsForAction(data)
          : await fetchSmartBenchmarking(data);
      }
    } else {
      response = await fetchFavoritesSmartBenchmarking(data);
    }
    if (response && response.ok) {
      const tableData = response.parsedBody.results.funds;

      setRows(
        tableData.map((p: SmartFundBenchmarkingItemProps) => createRow(p)),
      );
      setResultsNumber(response.parsedBody.count);
      setLoading(false);
      if (peerGroup) {
        const section = document.getElementById(peerGroup);

        if (section) {
          section.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }
    }
  };

  useEffect(() => {
    const fundId = searchParams.get('id');
    const collapseTableTab = searchParams.get('collapse-table-tab');
    const crashDrawdownsTableTab = searchParams.get('table-tab');

    if (
      fundId !== prevFundId ||
      collapseTableTab !== prevCollapseTableTab ||
      crashDrawdownsTableTab !== prevCrashDrawdownsTableTab
    ) {
      setPrevFundId(fundId);
      setPrevCollapseTableTab(collapseTableTab);
      setPrevCrashDrawdownsTableTab(crashDrawdownsTableTab);

      return;
    }
    if (isPeerGroupTab && !peerGroup) {
      getPeerGroupData();
      return;
    }
    getTableData();
  }, [
    itemsPerPage,
    searchValue,
    peerGroupSearchValue,
    peerGroup,
    sortModel,
    asset_class,
    searchParams,
  ]);

  useEffect(() => {
    if (isPeerGroupTab && peerGroup) {
      getPeerGroupData();
    }
  }, [isPeerGroupTab, peerGroup]);

  const getPeerGroupData = async () => {
    const data = {
      ...(peerGroupSearchValue ? { search: peerGroupSearchValue } : {}),
      ...(asset_class
        ? {
            asset_class: `${asset_class.charAt(0).toUpperCase()}${asset_class
              .slice(1)
              .toLowerCase()}`,
          }
        : {}),
    };
    setLoading(true);
    const response = await fetchSmartBenchmarkingPeerGroups(data);
    if (response.ok) {
      const data = response.parsedBody;
      setPeerGroupTabs(
        data.map((item) => ({
          label: item.name,
          count: item.funds_count,
          id: item.id,
        })),
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    const section = document.getElementById(peerGroup);

    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [peerGroupTabs, peerGroup]);

  const showConfirmRemoveFromFundListModal = (name: string, id: string) => {
    setIsConfirmRemoveFromFundOpen(true);
    setCurrentFund({ id, name, description: '' });
  };

  const showConfirmRemoveFromComparisonListModal = (
    name: string,
    id: string,
  ) => {
    setIsConfirmRemoveFromComparisonOpen(true);
    setCurrentFund({ id, name, description: '' });
  };

  const showAddFeedbackModal = (name: string, id: string) => {
    if (name) {
      setIsAddingFeedbackModalOpen(true);
      setCurrentFund({ id, name, description: '' });
    }
  };

  const changeFundListStatus = async (id: string, name: string) => {
    const response = await fetchUpdateFundListStatusSmartBenchmarking(id);
    if (response.ok) {
      getTableData();
      isConfirmRemoveFromFundOpen && setIsConfirmRemoveFromFundOpen(false);
      isConfirmRemoveFromFundOpen
        ? raiseToast.removeFromFundList(
            <Toast
              title="Fonds aus Ihrer Fondsliste entfernt"
              content={`"${name}" wurde aus Ihrer Fondsliste entfernt.`}
            />,
          )
        : raiseToast.addToFundList(
            <Toast
              title="Fonds zu Ihrer Fondsliste hinzugefügt"
              content={`"${name}" wurde zu Ihrer Fondsliste hinzugefügt.`}
            />,
          );
    }
  };

  const changeComparisonListStatus = async (id: string, name: string) => {
    const response = await fetchUpdateComparisonListStatusSmartBenchmarking(id);
    if (response.ok) {
      getTableData();
      isConfirmRemoveFromComparisonOpen &&
        setIsConfirmRemoveFromComparisonOpen(false);
      isConfirmRemoveFromComparisonOpen
        ? raiseToast.removeFromComparisonList(
            <Toast
              title="Fonds von Ihrer Vergleichsliste entfernt"
              content={`"${name}" wurde aus Ihrer Vergleichsliste entfernt.`}
            />,
          )
        : raiseToast.addToComparisonList(
            <Toast
              title="Fonds zu Ihrer Vergleichsliste hinzugefügt"
              content={`"${name}" wurde zu Ihrer Vergleichsliste hinzugefügt.`}
            />,
          );
    }
  };

  const requestReport = async (id: string, name: string) => {
    const response = await sendRequestReportSmartBenchmarking(id);
    if (response.ok) {
      raiseToast.success(
        <Toast
          title="Die Anfrage für den FondsConsult Nachhaltigkeitsbericht wurde erfolgreich gesendet"
          content={`Wir werden Ihnen eine E-Mail bezüglich des Fonds "${name}" senden`}
        />,
      );
    }
  };

  const requestVote = async (id: string, name: string) => {
    const response = await sendRequestVoteSmartBenchmarking(id);
    if (response.ok) {
      raiseToast.success(
        <Toast
          title="Die Anfrage für die FondsConsult Handlungsempfehlung wurde erfolgreich gesendet."
          content={`Wir werden Ihnen eine E-Mail bezüglich des Fonds "${name}" senden`}
        />,
      );
    }
  };

  const columns: SmartFundBenchmarkingColumn[] = [
    {
      id: 'name',
      cellAlign: 'left',
      headAlign: 'left',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: true,
      label: 'Name',
      order: 0,
      minWidth: '5fr',
      maxWidth: '100%',
      renderCell: ({ row }) => (
        <NameColumn name={row.name} category={row.category || ''} />
      ),
    },
    {
      id: 'vote',
      headAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Handlungs-empfehlung',
      maxTextWidth: '83px',
      order: 0,
      minWidth: '130px',
      maxWidth: '130px',
      infoTooltip:
        'Auf Grundlage quantitativer und qualitativer Analysen erarbeitete und beschlossene Empfehlungen des FondsConsult Investment Committees. (Mögliche Empfehlungen: BUY (Kaufen), HOLD (Halten), SWITCH (Verkaufen)).',
      renderCell: ({ value, row }) => (
        <Vote
          value={value}
          onRequestVote={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            requestVote(row.id, row.name);
          }}
        />
      ),
    },
    {
      id: 'rank',
      headAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Rang',
      order: 0,
      minWidth: '77px',
      maxWidth: '77px',
      infoTooltip:
        'Im Rahmen unserer proprietären Quant-Auswertung erzielter Rang des Fonds innerhalb seiner Vergleichsgruppe. Die Platzierung basiert auf diversen Rendite-/Risikokennzahlen über einen Zeitraum von einem bzw. drei Jahren.',
      renderCell: ({ row }) => (
        <Rank value={row.rank} maxValue={row.nr_of_funds} />
      ),
    },
    {
      id: 'volume',
      headAlign: 'center',
      cellAlign: 'center',
      dottedLeftBorder: true,
      isHighlighted: true,
      sortable: true,
      numeric: false,
      color: COLORS.info.selectedBorder,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Fonds-volumen',
      order: 0,
      minWidth: '133px',
      maxWidth: '133px',
      largeScreenWidth: '130px',
      infoTooltip:
        'Fondsvolumen eines Fonds im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Hoch (Top 30%), Mittel, Niedrig (Untere 30%).',
      renderCell: ({ value }) => {
        const currentValue = getDefaultFilterValue(value, volumeOptions);
        return !value || value === '-' ? (
          <span>-</span>
        ) : (
          <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
            <StyledTableChipContainer>
              <TableChip
                label={value.toLowerCase() as ChipVariant}
                tableChipVaraint={currentValue.chipVariant}
              />
            </StyledTableChipContainer>
          </Box>
        );
      },
    },
    {
      id: 'track_record',
      headAlign: 'center',
      cellAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      isHighlighted: true,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Track Record',
      color: COLORS.info.selectedBorder,
      maxTextWidth: '133px',
      order: 0,
      minWidth: '132px',
      maxWidth: '132px',
      largeScreenWidth: '130px',
      infoTooltip:
        'Laufzeit eines Fonds im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Lang (Top 30%), Mittel, Kurz (Untere 30%).',
      renderCell: ({ value }) => {
        const currentValue = getDefaultFilterValue(value, trackRecordOptions);

        return !value || value === '-' ? (
          <span>-</span>
        ) : (
          <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
            <StyledTableChipContainer>
              <TableChip
                label={value.toLowerCase() as ChipVariant}
                tableChipVaraint={currentValue.chipVariant}
              />
            </StyledTableChipContainer>
          </Box>
        );
      },
    },
    {
      id: 'return',
      headAlign: 'center',
      cellAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Rendite',
      isHighlighted: true,
      order: 0,
      minWidth: '110px',
      maxWidth: '110px',
      largeScreenWidth: '130px',
      infoTooltip:
        'Historisch erzielte Rendite des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
      color: COLORS.info.selectedBorder,
      renderCell: ({ value }) => {
        const currentValue = getDefaultFilterValue(value, returnOptions);

        return !value || value === '-' ? (
          <span>-</span>
        ) : (
          <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
            <StyledTableChipContainer>
              <TableChip
                label={value.toLowerCase() as ChipVariant}
                tableChipVaraint={currentValue.chipVariant}
              />
            </StyledTableChipContainer>
          </Box>
        );
      },
    },
    {
      id: 'risk',
      headAlign: 'center',
      cellAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Risiko',
      isHighlighted: true,
      order: 0,
      maxWidth: '100px',
      largeScreenWidth: '130px',
      color: COLORS.info.selectedBorder,
      minWidth: '100px',
      infoTooltip:
        'Gemessen über die Volatilität, den max. Verlust, das Beta sowie die eigens entwickelte Downside Protection des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht, Sehr Schlecht (Untere 10%)).',
      renderCell: ({ value }) => {
        const currentValue = getDefaultFilterValue(value, riskOptions);

        return !value || value === '-' ? (
          <span>-</span>
        ) : (
          <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
            <StyledTableChipContainer>
              <TableChip
                label={value.toLowerCase() as ChipVariant}
                tableChipVaraint={currentValue.chipVariant}
              />
            </StyledTableChipContainer>
          </Box>
        );
      },
    },
    {
      id: 'total_active_management',
      headAlign: 'center',
      cellAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Aktives Management',
      isHighlighted: true,
      order: 0,
      minWidth: '120px',
      maxWidth: '120px',
      largeScreenWidth: '130px',
      infoTooltip:
        'Gemessen über das zur Benchmark erzielte Alpha, sowie die Information und Appraisal Ratio des Fonds (Mittelwert aus verschiedenen Zeiträumen von sechs Monaten bis zu zehn Jahren) im Vergleich zu anderen Fonds seiner Peergroup. (Mögliche Bewertungen: Sehr gut (Top 10%), Gut (darauffolgende 25%), Mittel (mittlere 30%), Schlecht (darauffolgende 25%), Sehr Schlecht (Untere 10%)).',
      color: COLORS.info.selectedBorder,
      renderCell: ({ value }) => {
        const currentValue = getDefaultFilterValue(
          value,
          activeManagementOptions,
        );
        return !value || value === '-' ? (
          <span>-</span>
        ) : (
          <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
            <StyledTableChipContainer>
              <TableChip
                label={value.toLowerCase() as ChipVariant}
                tableChipVaraint={currentValue.chipVariant}
              />
            </StyledTableChipContainer>
          </Box>
        );
      },
    },
    {
      id: 'credibility_score',
      headAlign: 'center',
      sortable: true,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'ESG Credibility',
      dottedLeftBorder: true,
      order: 0,
      minWidth: '100px',
      maxWidth: '100px',
      infoTooltip:
        'Bewertung der Glaubwürdigkeit der Nachhaltigkeitsbemühungen einer Fondsgesellschaft. Score erstreckt sich von 0 bis 100 Punkten, wobei ab 60 Punkten von einer hohen Glaubwürdigkeit gesprochen wird.',
      renderCell: ({ value }) => <Score value={value} />,
    },
    {
      id: 'actions',
      headAlign: 'right',
      cellAlign: 'right',
      sortable: false,
      numeric: false,
      disablePadding: false,
      flexBasisMobView: '',
      isShowTooltip: false,
      label: 'Actions',
      order: 0,
      maxWidth: '135px',
      minWidth: '135px',
      renderCell: ({ row }) => (
        <Actions
          variant="short"
          isInMyFundList={row.is_favoured || false}
          isInComparisonList={row.is_compared || false}
          onChangeFundListHandle={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            row.is_favoured
              ? showConfirmRemoveFromFundListModal(row.name, row.id)
              : changeFundListStatus(row.id, row.name);
          }}
          onChangeComparisonListHandle={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            row.is_compared
              ? showConfirmRemoveFromComparisonListModal(row.name, row.id)
              : changeComparisonListStatus(row.id, row.name);
          }}
          onRequestReportHandler={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            requestReport(row.id, row.name);
          }}
          onFeedbackHandle={(e: React.BaseSyntheticEvent) => {
            e.stopPropagation();
            showAddFeedbackModal(row.name, row.id);
          }}
        />
      ),
    },
  ];

  const getPagesNumber = () => {
    return Math.ceil(resultsNumber / +itemsPerPage);
  };

  const addMultipleFundsToMyWatchlist = async () => {
    const response = await fetchAddFundsToWatchlistSmartBenchmarking(
      selectedRows,
    );
    if (response.ok) {
      raiseToast.addToFundList(
        'Fonds, die zu Ihrer Fondsliste hinzugefügt wurden.',
      );
      setSelectedRows([]);
      getTableData();
    }
  };

  const isAddMultipleFundsButtonEnabled = () => {
    return rows
      .filter((item) => selectedRows.includes(item.id))
      .some((row) => !row.is_favoured);
  };

  const getFilters = (filtersOptions: SFBFiltersProps[]) => {
    const filters: ({
      value: string;
      name: string;
      category: string;
    } & FilterProps)[] = [];

    const addFilter = (
      value: string,
      name: string,
      label: string,
      id: string,
      category: string,
    ) => {
      filters.push({
        value,
        name: toTitleCase(name) || '',
        label,
        id,
        category,
      });
    };

    const processFilterValue = (
      filterValue: string[],
      id: string,
      type: string,
      innerCategoryName: string,
      options: any[],
      parentCategoryType: string,
    ) => {
      if (type === 'checkboxes-regions') {
        filterValue.forEach((item) =>
          addFilter(item, item, innerCategoryName, id, parentCategoryType),
        );
        return;
      }

      if (type === 'search' || type === 'range') {
        addFilter(
          filterValue[0],
          filterValue[0],
          innerCategoryName,
          id,
          parentCategoryType,
        );
        return;
      }

      const result = options.filter((option) =>
        filterValue.includes(option.value.toLowerCase()),
      );
      result.forEach((item) => {
        const value = item?.value;
        const name =
          type === 'chips-article' ? `Artikel ${item?.name}` : item?.name;
        addFilter(value, name, innerCategoryName, id, parentCategoryType);
      });
    };

    Object.values(filtersOptions).forEach((filter) =>
      filter.data.map(({ id, type, innerCategoryName, options }) => {
        const filterValue = searchParams.getAll(id.toString());
        if (filterValue) {
          processFilterValue(
            filterValue,
            id.toString(),
            type,
            innerCategoryName,
            options,
            filter.categoryType,
          );
        }
      }),
    );

    return filters;
  };

  const getActiveFiltersNumber = (filtersOptions: SFBFiltersProps[]) => {
    const filters = getFilters(filtersOptions).filter((item) => !!item.value);
    return filters.length;
  };

  return (
    <>
      <RemoveFromFundListModal
        isShown={isConfirmRemoveFromFundOpen}
        toggle={() => setIsConfirmRemoveFromFundOpen(false)}
        onSubmit={() => changeFundListStatus(currentFund.id, currentFund.name)}
      />
      <RemoveFromComparisonListModal
        isShown={isConfirmRemoveFromComparisonOpen}
        toggle={() => setIsConfirmRemoveFromComparisonOpen(false)}
        onSubmit={() =>
          changeComparisonListStatus(currentFund.id, currentFund.name)
        }
      />
      <AddFeedbackModal
        withInterestLevel
        variant="smart-benchmarking"
        isShown={isAddingFeedbackModalOpen}
        currentFund={currentFund}
        toggle={() => setIsAddingFeedbackModalOpen(false)}
      />
      <SFBFiltersModal
        isShow={openFilters}
        closeModal={() => setOpenFilters(false)}
        clearFilters={clearFilters}
        filters={isPeerGroupTab ? peerGroupFilters : sfbFilters}
        selectedFilters={getFilters(
          isPeerGroupTab ? peerGroupFilters : sfbFilters,
        ).filter((item) => !!item.value)}
      />
      <ModuleLayout
        mb={isPeerGroupTab ? 2.5 : 5}
        withTabs={!isRecommendationsVariant}
        searchValue={isPeerGroupTab ? peerGroupSearchValue : searchValue}
        searchPlaceholder={
          isPeerGroupTab
            ? 'Suche über Peergroup Name'
            : 'Fondssuche über ISIN oder Name...'
        }
        activeFiltersAmount={getActiveFiltersNumber(
          isPeerGroupTab ? peerGroupFilters : sfbFilters,
        )}
        onSubmitSearch={onSubmitSearch}
        onToggleFilters={toggleFiltersSection}
      >
        <SelectedFilters
          filters={getFilters(isPeerGroupTab ? peerGroupFilters : sfbFilters)}
        />
        {!isPeerGroupTab && (
          <SFBTable
            variant={variant}
            loading={loading}
            peerGroup={isPeerGroupTab}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            columns={columns}
            rows={rows}
            pagesNumber={getPagesNumber()}
            addMultipleFundsToMyWatchlist={addMultipleFundsToMyWatchlist}
            isAddMultipleFundsButtonEnabled={isAddMultipleFundsButtonEnabled()}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            loadingColumnWidth={getTableHeadWidths()}
          />
        )}
      </ModuleLayout>
      {isPeerGroupTab && (
        <Box px={2.2} pb={5}>
          <Accordion
            onTabChange={() => setSelectedRows([])}
            tabs={peerGroupTabs.map((tab) => ({
              label: tab.label,
              count: tab.count,
              id: tab.id,
              content: (
                <SFBTable
                  variant={variant}
                  loading={loading}
                  peerGroup={isPeerGroupTab}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  columns={columns}
                  rows={rows}
                  pagesNumber={getPagesNumber()}
                  isAddMultipleFundsButtonEnabled={isAddMultipleFundsButtonEnabled()}
                  addMultipleFundsToMyWatchlist={addMultipleFundsToMyWatchlist}
                  sortModel={sortModel}
                  onSortModelChange={handleSortModelChange}
                  loadingColumnWidth={getTableHeadWidths()}
                />
              ),
            }))}
          />
        </Box>
      )}
    </>
  );
};
