import styled from 'styled-components';
import { StyledLogoProps } from './StartView.d';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { Link } from 'react-router-dom';

export const StyledLogo = styled(Logo)`
  margin: 25px auto 0;
  width: 180px;
`;

export const StyledLink = styled(Link)<StyledLogoProps>`
  &:hover {
    ${({ isClickable }) => !isClickable && 'cursor: unset;'}
  }
`;
