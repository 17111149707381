import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledTypography = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.info.selectedBorder};
  margin-bottom: 16px;
`;
