import { forwardRef } from "react";
import { StyledDiv, StyledSpan } from "./Box.styled";
import { BoxProps } from "./Box.d";

// eslint-disable-next-line react/display-name
export const Box = forwardRef<HTMLDivElement, BoxProps>(
  ({ display, variant = "div", ...props }, ref) => {
    const StyledBox = variant === "div" ? StyledDiv : StyledSpan;

    return (
      <StyledBox
        ref={ref}
        {...props}
        /**
         * Made transient to prevent from being passed into DOM element, because
         * otherwise it could throw errors while passing `false` in some scenarios.
         * https://styled-components.com/docs/api#transient-props
         */
        $display={display}
      />
    );
  }
);
