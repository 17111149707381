import { Typography } from 'components/common/Typography';
import styled, { css } from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledHeader = styled(Typography)`
  font-size: 36px;
`;

const linkStyles = css`
  color: ${COLORS.typography.body};
  transition-duration: 0.2s;

  &:hover {
    color: ${COLORS.accent.primary};
    text-decoration: none;
  }
`;

export const StyledLink = styled.a`
  ${linkStyles}
`;

export const StyledItemsList = styled.ol`
  padding-inline-start: 33px;
  margin: 0;

  counter-reset: item;
  list-style: none;
  padding-left: 20px;

  > li {
    counter-increment: item;
    position: relative;
    margin-bottom: 10px;
    margin-top: 32px;
    font-weight: 600;
  }

  > li::before {
    content: counters(item, '.') '. ';
    position: absolute;
    left: -13px;
  }

  ol {
    counter-reset: subitem;
    list-style: none;
    padding-left: 20px;
    margin-top: 8px;

    > li {
      counter-increment: subitem;
      position: relative;
      margin-bottom: 5px;
      font-weight: 400;
    }

    > li::before {
      content: counters(item, '.') '.' counter(subitem) '. ';
      position: absolute;
      left: -30px;
    }
  }
`;

export const StyledItem = styled.li`
  font-size: 16px;
  line-height: 29px;
  font-family: 'Inter', sans-serif;
  color: ${COLORS.typography.description};

  padding-left: 8px;
  margin-bottom: 10px;

  & span {
    font-weight: 600;
    color: ${COLORS.typography.main};
  }

  & p {
    font-weight: 400;
  }
`;
