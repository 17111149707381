import { useState, FC } from 'react';
import { FilterAccordionType } from './FilterAccordion.d';
import { slugify } from 'utils/common';
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledCount,
  StyledItemContainer,
  StyledLabel,
} from './FilterAccordion.styled';
import { ReactComponent as ArrowIcon } from 'assets/Icons/arrow-up.svg';
import { COLORS } from 'theme/colors';

export const FilterAccordion: FC<FilterAccordionType> = ({
  variant = 'default',
  tabs,
  onTabChange,
}) => {
  const isSmallVariant = variant === 'small';
  const [activeTabs, setActiveTabs] = useState<string[]>([]);

  const handleChange =
    (tabId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      event.preventDefault();
      onTabChange && onTabChange(tabId);
      setActiveTabs((prevActiveTabs: string[]) => {
        if (isExpanded) {
          return [...prevActiveTabs, slugify(tabId)];
        } else {
          return prevActiveTabs.filter((id) => id !== slugify(tabId));
        }
      });
    };

  return (
    <>
      {tabs.length !== 0 &&
        tabs.map((tab) => {
          const isActive = activeTabs.includes(slugify(tab.id));

          return (
            <StyledAccordion
              key={tab.id}
              expanded={isActive}
              onChange={handleChange(tab.id)}
              disableGutters
              elevation={0}
              square
              disabled={tab.disabled}
              slotProps={{ transition: { unmountOnExit: true } }}
            >
              <StyledAccordionSummary
                isSmallVariant={isSmallVariant}
                isActive={isActive}
                id={tab.id}
                expandIcon={
                  <ArrowIcon
                    fill={COLORS.typography.main}
                    width={24}
                    height={24}
                  />
                }
              >
                <StyledItemContainer>
                  <StyledLabel variant="body" isSmallVariant={isSmallVariant}>
                    {tab.label}
                  </StyledLabel>
                  {tab.count !== null && tab.count !== undefined ? (
                    <StyledCount className="count" active={isActive}>
                      {tab.count}
                    </StyledCount>
                  ) : null}
                </StyledItemContainer>
              </StyledAccordionSummary>
              <StyledAccordionDetails isSmallVariant={isSmallVariant}>
                {tab.content}
              </StyledAccordionDetails>
            </StyledAccordion>
          );
        })}
    </>
  );
};
