import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  styled as MUIstyled,
} from '@mui/material';
import { COLORS } from 'theme/colors';
import { StyledFormGroupProps } from './CheckboxFilters.d';
import styled from 'styled-components';
import { Typography } from 'components/common/Typography';

export const StyledFormGroup = MUIstyled(FormGroup)<StyledFormGroupProps>(
  ({ itemsCount }) => ({
    marginLeft: '6px',
    gap: '20px',
    display: 'grid',
    gridTemplateRows: `repeat(${Math.ceil(itemsCount / 2)}, 1fr)`,
    gridAutoFlow: 'column',
  }),
);

export const StyledCheckbox = MUIstyled(Checkbox)({
  padding: '0 8px',
});

export const StyledFormControlLabel = MUIstyled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    fontFamily: '"Inter", sans-serif',
    fontSize: '14px',
    lineHeight: '17px',
    color: COLORS.typography.main,
  },
  marginRight: 0,
});

export const StyledTypography = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
  color: ${COLORS.info.selectedBorder};
  margin-bottom: 16px;
`;
