import { FC } from 'react';
import { TrendProps } from './Trend.d';
import {
  StyledArrow,
  StyledArrowContainer,
  StyledContainer,
  StyledInfoIcon,
} from './Trend.styled';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { CustomTooltip } from 'components/common/Tooltip';
import { COLORS } from 'theme/colors';
import { ReactComponent as ArrowIcon } from 'assets/Icons/back.svg';
import { infoTooltip } from './config';

export const Trend: FC<TrendProps> = ({
  trend,
  trendTitle,
  trendTooltip = infoTooltip,
}) => {
  const getRotateArrowValue = () => {
    switch (trend) {
      case 'Positive':
        return 135;
      case 'Negative':
        return -135;
      default:
        return 180;
    }
  };

  return (
    <StyledContainer display="flex" flexDirection="column">
      <Box
        py={0.8}
        px={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body" weight="bold" textAlign="center">
          {trendTitle || 'Trend'}
        </Typography>
        <CustomTooltip title={trendTooltip} placement="top">
          <StyledInfoIcon stroke={COLORS.typography.placeholder} />
        </CustomTooltip>
      </Box>
      <StyledArrowContainer py={2.7} trend={trend}>
        {trend === null || trend === 'None' || trend === 'Unknown' ? (
          <Typography variant="body" textAlign="center">
            Kein Trend erkennbar
          </Typography>
        ) : (
          <StyledArrow trend={trend}>
            <ArrowIcon
              stroke={COLORS.background.secondary}
              width="16px"
              height="16px"
              transform={`rotate(${getRotateArrowValue()})`}
            />
          </StyledArrow>
        )}
      </StyledArrowContainer>
    </StyledContainer>
  );
};
