import { ChangePasswordForm } from 'components/User/ChangePasswordForm';
import { ChangePasswordFields } from 'components/User/ChangePasswordForm/ChangePasswordForm.d';
import { StartView } from 'components/User/StartView';
import { Box } from 'components/common/Box';
import { FC, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPassword } from 'services/Auth';
import { AppPaths } from 'urls/frontend';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const ChangePassword: FC = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const token = query.get('token');

  useEffect(() => {
    if (!token) navigate(AppPaths.main);
  }, [navigate, token]);

  const changePassword = async (data: ChangePasswordFields) => {
    const { password } = data;
    if (token) {
      const response = await resetPassword({ password, token });
      if (response.ok) {
        navigate(AppPaths.resetPasswordConfirmed);
      }
    }
  };

  return (
    <StartView headline="Create New Password">
      <Box style={{ width: '33%', margin: '25px auto' }}>
      <ChangePasswordForm onSubmit={changePassword} />
      </Box>
    </StartView>
  );
};
