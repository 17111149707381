import { CONTACT_US_URL, WEBSITE_CONTACT_US_URL } from 'urls/api';
import fetchApi from './fetchApi';
import { ContactUsForm, RegisterContactUsForm } from 'types/User';
import fetchAPIWithoutToken from './fetchApiWithoutToken';

export const contactUs = (data: ContactUsForm) => {
  return fetchApi(CONTACT_US_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
};

export const registerContactUs = (data: RegisterContactUsForm) => {
  return fetchAPIWithoutToken(WEBSITE_CONTACT_US_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
};
