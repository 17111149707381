import { FC, PropsWithChildren } from 'react';
import { Header } from '../Header/Header';
import { LayoutProps } from './Layout.d';
import { PageContainer, StyledContainer } from './Layout.styled';
import { useProfile } from 'context/Profile';
import { StartView } from 'components/User/StartView';

export const Layout: FC<PropsWithChildren<LayoutProps>> = ({
  headerVariant = 'primary',
  children,
}) => {
  const profile = useProfile();

  return (
    <>
      {!!profile?.profileInfo ? (
        <StyledContainer>
          <Header variant={headerVariant} />
          <PageContainer>{children}</PageContainer>
        </StyledContainer>
      ) : (
        <StartView>{children}</StartView>
      )}
    </>
  );
};
