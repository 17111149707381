import * as Yup from 'yup';

export const inviteUserSchema = Yup.object({
  salutation: Yup.string().required('Bitte geben Sie Ihre E-Mail-Adresse ein.'),
  email: Yup.string()
    .email('Bitte geben Sie eine gültige E-Mail-Adresse ein.')
    .required('Bitte geben Sie Ihre E-Mail-Adresse ein.'),
  first_name: Yup.string()
    .required('Bitte geben Sie den Vornamen ein.')
    .test(
      'empty-check',
      'Der Vorname darf nicht leer sein.',
      (name) => name.trim().length !== 0,
    ),
  second_name: Yup.string()
    .required('Bitte geben Sie den Nachnamen ein.')
    .test(
      'empty-check',
      'Der Nachname darf nicht leer sein.',
      (name) => name.trim().length !== 0,
    ),
});
