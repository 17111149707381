import { FC } from 'react';
import { FundManagementAlternativesETFProps } from './FundManagementAlternativesETF.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import {
  StyledInfoContainer,
  StyledInfoItem,
  StyledTypography,
} from './FundManagementAlternativesETF.styled';
import { TableChip } from 'components/common/TableChip';
import { ChipVariant } from 'components/common/TableChip/TableChip.d';
import { COLORS } from 'theme/colors';
import { getFilterByValue } from 'components/Modules/Tables/FCSmartFundBenchmarkingTable/utils';
import {
  averageDailyTradingVolumeOptions,
  costsSpreadOptions,
  managementResponsibilityOptions,
} from './config';

export const FundManagementAlternativesETF: FC<
  FundManagementAlternativesETFProps
> = ({
  description,
  managementResponsibility,
  spread,
  costs,
  averageDailyTradingVolume,
}) => {
  const data = [
    ...(managementResponsibility
      ? [
          {
            label: 'Managementzugehörigkeit',
            value: (
              <TableChip
                size="small"
                label={managementResponsibility.toLowerCase() as ChipVariant}
                tableChipVaraint={
                  getFilterByValue(
                    managementResponsibility,
                    managementResponsibilityOptions,
                  )?.chipVariant
                }
              />
            ),
          },
        ]
      : []),
    ...(spread
      ? [
          {
            label: 'Spread',
            value: (
              <TableChip
                size="small"
                label={spread.toLowerCase() as ChipVariant}
                tableChipVaraint={
                  getFilterByValue(spread, costsSpreadOptions)?.chipVariant
                }
              />
            ),
          },
        ]
      : []),
    ...(costs
      ? [
          {
            label: 'Kosten',
            value: (
              <TableChip
                size="small"
                label={costs.toLowerCase() as ChipVariant}
                tableChipVaraint={
                  getFilterByValue(costs, costsSpreadOptions)?.chipVariant
                }
              />
            ),
          },
        ]
      : []),
    ...(averageDailyTradingVolume
      ? [
          {
            label: 'Durchschnittliches tägliches Handelsvolumen',
            value: (
              <TableChip
                size="small"
                label={averageDailyTradingVolume.toLowerCase() as ChipVariant}
                tableChipVaraint={
                  getFilterByValue(
                    averageDailyTradingVolume,
                    averageDailyTradingVolumeOptions,
                  )?.chipVariant
                }
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <Box>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      {data.length !== 0 && (
        <StyledInfoContainer py={3} my={2} display="grid">
          {data.map((item) => (
            <StyledInfoItem
              key={item.label}
              display="flex"
              justifyContent="space-between"
            >
              <StyledTypography variant="body" weight="semibold">
                {item.label}
              </StyledTypography>
              {item.value}
            </StyledInfoItem>
          ))}
        </StyledInfoContainer>
      )}
    </Box>
  );
};
