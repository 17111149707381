import { FC, useEffect, useState } from 'react';
import { HeaderProps } from './Header.d';
import {
  StyledContainer,
  StyledDescriptionContainer,
  StyledShowMoreButton,
} from './Header.styled';
import { ArrowIconButton } from 'components/common/IconButtons/ArrowIconButton';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { AppPaths } from 'urls/frontend';
import { useLocation } from 'react-router-dom';
import { ButtonVariant } from 'components/common/Buttons/Button/Button.enums';
import { ReactComponent as ArrowCollapseIcon } from 'assets/Icons/collapse-arrow.svg';

export const Header: FC<HeaderProps> = ({
  title,
  descriptions,
  icon,
  showMoreTexts,
  showMoreButtonText,
}) => {
  const [prevPath, setPrevPath] = useState(AppPaths.main);
  const location = useLocation();
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    location.state?.prevPath && setPrevPath(location.state.prevPath);
  }, [location.state]);

  return (
    <StyledContainer px={2.5} py={5} display="flex" gap={4}>
      <ArrowIconButton variant="back" to={prevPath} />
      <Box
        display="flex"
        flexDirection={!!descriptions?.length ? 'column' : 'row'}
        alignItems={!!descriptions?.length ? 'flex-start' : 'center'}
      >
        <Box display="flex" gap={2} mb={!!descriptions?.length ? 2 : 0}>
          {icon}
          <Typography variant="h4" color={COLORS.typography.primary}>
            {title}
          </Typography>
        </Box>
        {!!descriptions?.length && (
          <StyledDescriptionContainer
            display="flex"
            flexDirection="column"
            gap={1}
          >
            {descriptions.map((text, index) => (
              <Typography
                key={index}
                variant="body"
                color={COLORS.typography.description}
              >
                {text}
              </Typography>
            ))}
          </StyledDescriptionContainer>
        )}
        {showMoreButtonText && (
          <Box py={2}>
            <StyledShowMoreButton
              variant={ButtonVariant.TEXT}
              rightIcon={
                <ArrowCollapseIcon
                  stroke={
                    showMore ? COLORS.accent.primary : COLORS.typography.main
                  }
                  style={{
                    transform: showMore ? 'rotate(-90deg)' : 'rotate(90deg)',
                  }}
                />
              }
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? 'Weniger anzeigen' : showMoreButtonText}
            </StyledShowMoreButton>
          </Box>
        )}
        {showMoreTexts && showMoreButtonText && showMore && (
          <Box display="flex" flexDirection="column" gap={2}>
            {showMoreTexts.map((text, index) => (
              <Typography
                key={index}
                variant="body"
                color={COLORS.typography.description}
              >
                {text}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
    </StyledContainer>
  );
};
