import { StyledLink, StyledLogo } from './StartView.styled';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography/Typography';
import { FC } from 'react';
import { StartViewProps } from './StartView.d';
import { useLocation } from 'react-router-dom';
import { AppPaths } from 'urls/frontend';

export const StartView: FC<StartViewProps> = ({ headline, icon, children }) => {
  const { pathname } = useLocation();
  const isHomePage = pathname === AppPaths.main || pathname === AppPaths.login;

  return (
    <>
      <Box my={0} mx={5}>
        <StyledLink to={AppPaths.main} isClickable={!isHomePage}>
          <StyledLogo />
        </StyledLink>
      </Box>
      {!!icon && (
        <Box display="flex" justifyContent="center" mb={2}>
          {icon}
        </Box>
      )}
      {!!headline && (
        <Box display="flex" justifyContent="center" pt={12}>
          <Typography variant="h2">{headline}</Typography>
        </Box>
      )}
      <Box>{children}</Box>
    </>
  );
};
