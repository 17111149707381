import { Box } from 'components/common/Box';
import styled from 'styled-components';
import {
  StyledPinButtonProps,
  StyledPointProps,
} from './TableHeaderWithActions.d';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';

export const StyledContainer = styled(Box)`
  position: relative;
`;

export const StyledPoint = styled(Box)<StyledPointProps>`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background-color: ${({ color }) => color};
  margin-top: 3px;
`;

export const StyledName = styled(Typography)`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-overflow: ellipsis;
  display: -webkit-box;
  flex: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
`;

export const StyledMoreActionsButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: unset;
  cursor: pointer;
  transition-duration: 0.2s;

  &:hover {
    svg {
      stroke: ${COLORS.background.hover};
    }
  }

  &:active {
    svg {
      stroke: ${COLORS.accent.hover};
    }
  }
`;

export const StyledPinButton = styled.button<StyledPinButtonProps>`
  margin: 0;
  padding: 0;
  border: none;
  background-color: unset;
  ${({ isPinned }) => !isPinned && 'cursor: pointer;'}
  transition-duration: 0.2s;

  &:hover {
    svg {
      stroke: ${({ isPinned }) => !isPinned && COLORS.background.hover};
    }
  }
`;

export const StyledArrowContainer = styled(Box)`
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
`;
export const StyledArrow = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.2s;

  width: 20px;
  height: 20px;
  border-radius: 5px;

  background-color: ${COLORS.info.selectedBorder};

  &:enabled {
    cursor: pointer;

    &:hover {
      background-color: ${COLORS.background.hover};
    }
  }

  &:disabled {
    background-color: ${COLORS.stroke.primary};
  }
`;
