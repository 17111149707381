import { FC } from 'react';
import { ReturnProps } from './Return.d';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { StyledContainer } from './Return.styled';
import { AnnualizedTable } from '../AnnualizedTable';
import { SFBBoxWithTrend } from '../SFBBoxWithTrend';
import { COLORS } from 'theme/colors';
import { headTooltip, periods, trendTooltip } from './config';

export const Return: FC<ReturnProps> = ({
  description,
  standDate,
  sfbData,
  annualizedData,
  trend,
}) => {
  return (
    <Box>
      <Typography variant="body" color={COLORS.typography.description}>
        {description}
      </Typography>
      <StyledContainer pt={5} display="grid" gridAutoFlow="column">
        <SFBBoxWithTrend
          data={sfbData}
          trend={trend}
          periods={periods}
          trendTitle="Trend Rendite"
          trendTooltip={trendTooltip}
          headTooltip={headTooltip}
        />
        <AnnualizedTable
          title="Rendite"
          date={standDate}
          data={annualizedData}
        />
      </StyledContainer>
    </Box>
  );
};
